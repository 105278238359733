/* eslint-disable */
import { DocumentTypeDecoration } from "@graphql-typed-document-node/core"
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  AWSDate: { input: any; output: any }
  AWSDateTime: { input: any; output: any }
  AWSEmail: { input: any; output: any }
  AWSIPAddress: { input: any; output: any }
  AWSJSON: { input: any; output: any }
  AWSPhone: { input: any; output: any }
  AWSTime: { input: any; output: any }
  AWSTimestamp: { input: any; output: any }
  AWSURL: { input: any; output: any }
}

export enum AddressTypeCode {
  BusinessAddress = "BUSINESS_ADDRESS",
  DeliveryToAddress = "DELIVERY_TO_ADDRESS",
  FiscalAddress = "FISCAL_ADDRESS",
  MailToAddress = "MAIL_TO_ADDRESS",
  PhysicalAddress = "PHYSICAL_ADDRESS",
  PostalAddress = "POSTAL_ADDRESS",
  PostboxAddress = "POSTBOX_ADDRESS",
  ResidentialAddress = "RESIDENTIAL_ADDRESS",
}

export type ApproveCertificateRequestInput = {
  certifier: SpsAuthenticationInput
  requestor?: InputMaybe<Scalars["String"]["input"]>
  requestor_id: Scalars["String"]["input"]
  status: ExportCertificateStatus
}

export type AvailableTemplateFields = {
  __typename?: "AvailableTemplateFields"
  code: Scalars["String"]["output"]
  commodity?: Maybe<Scalars["String"]["output"]>
  destinationMarket: DestinationMarket
  displayedDescription?: Maybe<Scalars["String"]["output"]>
  exportCertificateType?: Maybe<CertificateType>
  id: Scalars["ID"]["output"]
  majorVersion: Scalars["Int"]["output"]
  minorVersion: Scalars["Int"]["output"]
  title: Scalars["String"]["output"]
}

export type B2GAddress = {
  __typename?: "B2GAddress"
  city?: Maybe<Scalars["String"]["output"]>
  cityId?: Maybe<Scalars["ID"]["output"]>
  countryId?: Maybe<Scalars["ID"]["output"]>
  countryName?: Maybe<Scalars["String"]["output"]>
  countrySubdivisionId?: Maybe<Scalars["ID"]["output"]>
  countrySubdivisionName?: Maybe<Scalars["String"]["output"]>
  postcode?: Maybe<Scalars["String"]["output"]>
  postcodeCode?: Maybe<Scalars["String"]["output"]>
  street?: Maybe<Scalars["String"]["output"]>
  typeCode?: Maybe<AddressTypeCode>
}

export type B2GAddressInput = {
  city?: InputMaybe<Scalars["String"]["input"]>
  cityId?: InputMaybe<Scalars["ID"]["input"]>
  countryId?: InputMaybe<Scalars["ID"]["input"]>
  countryName?: InputMaybe<Scalars["String"]["input"]>
  countrySubdivisionId?: InputMaybe<Scalars["ID"]["input"]>
  countrySubdivisionName?: InputMaybe<Scalars["String"]["input"]>
  postcode?: InputMaybe<Scalars["String"]["input"]>
  postcodeCode?: InputMaybe<Scalars["String"]["input"]>
  street?: InputMaybe<Scalars["String"]["input"]>
  typeCode?: InputMaybe<AddressTypeCode>
}

export type B2GAuthentication = {
  __typename?: "B2GAuthentication"
  actual?: Maybe<Scalars["AWSDateTime"]["output"]>
  includedClauses: Array<B2GClause>
  issueLocation?: Maybe<B2GLocation>
  locationProviderParty?: Maybe<B2GParty>
  providerParty?: Maybe<B2GParty>
  typeCode?: Maybe<NamedCode>
}

export type B2GAuthenticationInput = {
  actual?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  includedClauses?: InputMaybe<Array<B2GClauseInput>>
  issueLocation?: InputMaybe<B2GLocationInput>
  locationProviderParty?: InputMaybe<B2GPartyInput>
  providerParty?: InputMaybe<B2GPartyInput>
  typeCode?: InputMaybe<NamedCodeInput>
}

export type B2GBanWineBatchInput = {
  effectiveDate?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  market: DestinationMarket
  productId: Scalars["ID"]["input"]
  reason?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GCertificateDelivery = {
  __typename?: "B2GCertificateDelivery"
  certificateDeliveryMethod?: Maybe<B2GCertificateDeliveryMethod>
  certificateSigningOffice?: Maybe<Scalars["ID"]["output"]>
  collectionMethod?: Maybe<B2GCollectionMethod>
  copies?: Maybe<Scalars["Int"]["output"]>
  digitalScanRequired?: Maybe<Scalars["Boolean"]["output"]>
  dueDate?: Maybe<Scalars["AWSDateTime"]["output"]>
  recipientAddress?: Maybe<B2GAddress>
  recipientContactName?: Maybe<Scalars["String"]["output"]>
  recipientEmail?: Maybe<Scalars["String"]["output"]>
}

export type B2GCertificateDeliveryInput = {
  certificateDeliveryMethod?: InputMaybe<B2GCertificateDeliveryMethod>
  certificateSigningOffice?: InputMaybe<Scalars["ID"]["input"]>
  collectionMethod?: InputMaybe<B2GCollectionMethod>
  copies?: InputMaybe<Scalars["Int"]["input"]>
  digitalScanRequired?: InputMaybe<Scalars["Boolean"]["input"]>
  dueDate?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  recipientAddress?: InputMaybe<B2GAddressInput>
  recipientContactName?: InputMaybe<Scalars["String"]["input"]>
  recipientEmail?: InputMaybe<Scalars["String"]["input"]>
}

export enum B2GCertificateDeliveryMethod {
  OvernightCourierAuckland = "OVERNIGHT_COURIER_AUCKLAND",
  OvernightCourierChristchurch = "OVERNIGHT_COURIER_CHRISTCHURCH",
  UrgentCourier = "URGENT_COURIER",
}

export type B2GClassification = {
  __typename?: "B2GClassification"
  classCode?: Maybe<Scalars["String"]["output"]>
  className: Scalars["String"]["output"]
  systemId?: Maybe<Scalars["String"]["output"]>
  systemName: Scalars["String"]["output"]
}

export type B2GClassificationInput = {
  classCode?: InputMaybe<Scalars["String"]["input"]>
  className: Scalars["String"]["input"]
  systemId?: InputMaybe<Scalars["String"]["input"]>
  systemName: Scalars["String"]["input"]
}

export type B2GClause = {
  __typename?: "B2GClause"
  content?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
}

export type B2GClauseInput = {
  content?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["ID"]["input"]>
}

export enum B2GCollectionMethod {
  Delivery = "Delivery",
  Pickup = "Pickup",
}

export type B2GConsignment = {
  __typename?: "B2GConsignment"
  EORINumber?: Maybe<Scalars["String"]["output"]>
  carrier?: Maybe<B2GParty>
  consignee?: Maybe<B2GParty>
  consignor?: Maybe<B2GParty>
  consignorReference?: Maybe<Scalars["ID"]["output"]>
  containers: Array<B2GContainer>
  delivery?: Maybe<B2GParty>
  expectedArrivalDateTime?: Maybe<Scalars["AWSDateTime"]["output"]>
  exportExitDateTime?: Maybe<Scalars["AWSDateTime"]["output"]>
  grossWeight?: Maybe<Scalars["String"]["output"]>
  id: Scalars["ID"]["output"]
  items: Array<B2GTradeLineItem>
  loadingBasePort?: Maybe<B2GLocation>
  mainCarriageTransportMovements: Array<B2GTransportMovement>
  unloadingBasePort?: Maybe<B2GLocation>
  verifier?: Maybe<B2GParty>
  wineConsignment?: Maybe<B2GWineConsignment>
  /** @deprecated Use wineConsignment.shippingFormat */
  wineShippingFormat?: Maybe<WinePackaging>
}

export type B2GConsignmentInput = {
  EORINumber?: InputMaybe<Scalars["String"]["input"]>
  carrier?: InputMaybe<B2GPartyInput>
  consignee?: InputMaybe<B2GPartyInput>
  consignor?: InputMaybe<B2GPartyInput>
  consignorReference?: InputMaybe<Scalars["ID"]["input"]>
  containers?: InputMaybe<Array<B2GContainerInput>>
  delivery?: InputMaybe<B2GPartyInput>
  expectedArrivalDateTime?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  exportExitDateTime?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  grossWeight?: InputMaybe<Scalars["String"]["input"]>
  items?: InputMaybe<Array<B2GTradeLineItemInput>>
  loadingBasePort?: InputMaybe<B2GLocationInput>
  mainCarriageTransportMovements?: InputMaybe<Array<B2GTransportMovementInput>>
  unloadingBasePort?: InputMaybe<B2GLocationInput>
  verifier?: InputMaybe<B2GPartyInput>
  wineConsignment?: InputMaybe<B2GWineConsignmentInput>
  /** @deprecated Use wineConsignment.shippingFormat */
  wineShippingFormat?: InputMaybe<WinePackaging>
}

export type B2GContact = {
  __typename?: "B2GContact"
  email?: Maybe<Scalars["AWSEmail"]["output"]>
  personName?: Maybe<Scalars["String"]["output"]>
}

export type B2GContactInput = {
  email?: InputMaybe<Scalars["AWSEmail"]["input"]>
  personName?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GContainer = {
  __typename?: "B2GContainer"
  affixedSeals: Array<B2GSeal>
  containerId: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  temperatureSettings: Array<B2GTemperature>
}

export type B2GContainerInput = {
  affixedSeals?: InputMaybe<Array<B2GSealInput>>
  containerId: Scalars["String"]["input"]
  temperatureSettings?: InputMaybe<Array<B2GTemperatureInput>>
}

export type B2GCountry = {
  __typename?: "B2GCountry"
  id: Scalars["ID"]["output"]
  name?: Maybe<Scalars["String"]["output"]>
  subdivision?: Maybe<B2GCountrySubDivision>
}

export type B2GCountryInput = {
  id: Scalars["ID"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
  subdivision?: InputMaybe<B2GCountrySubDivisionInput>
}

export type B2GCountrySubDivision = {
  __typename?: "B2GCountrySubDivision"
  id?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
}

export type B2GCountrySubDivisionInput = {
  id?: InputMaybe<Scalars["String"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GDeclaration = {
  __typename?: "B2GDeclaration"
  eligibilityCriteria: Scalars["ID"]["output"]
  market?: Maybe<DestinationMarket>
  text?: Maybe<Scalars["String"]["output"]>
}

export type B2GDeclarationInput = {
  eligibilityCriteria: Scalars["ID"]["input"]
  market?: InputMaybe<DestinationMarket>
  text: Scalars["String"]["input"]
}

export type B2GEligibilityCriteriaMet = {
  __typename?: "B2GEligibilityCriteriaMet"
  effectiveDate?: Maybe<Scalars["AWSDateTime"]["output"]>
  eligibilityCriteria: Scalars["ID"]["output"]
  expiryDate?: Maybe<Scalars["AWSDateTime"]["output"]>
  processId: Scalars["ID"]["output"]
}

export type B2GEligibilityCriteriaMetInput = {
  effectiveDate?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  eligibilityCriteria: Scalars["ID"]["input"]
  expiryDate?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  processId: Scalars["ID"]["input"]
}

export type B2GEligibilityStatus = {
  __typename?: "B2GEligibilityStatus"
  expiryDate?: Maybe<Scalars["AWSDateTime"]["output"]>
  market: DestinationMarket
  status: B2GEligibilityStatusType
}

export enum B2GEligibilityStatusType {
  Eligible = "ELIGIBLE",
  Expired = "EXPIRED",
  NotEligible = "NOT_ELIGIBLE",
  NotRequested = "NOT_REQUESTED",
  Requested = "REQUESTED",
}

export enum B2GError {
  InvalidValue = "INVALID_VALUE",
  Required = "REQUIRED",
}

export type B2GExportCertificateRequest = {
  __typename?: "B2GExportCertificateRequest"
  certificateDelivery?: Maybe<B2GCertificateDelivery>
  certificateNumber?: Maybe<Scalars["String"]["output"]>
  certificateType?: Maybe<CertificateType>
  commodity?: Maybe<Scalars["String"]["output"]>
  consignment?: Maybe<B2GConsignment>
  destination?: Maybe<B2GCountry>
  exporterDeclaration?: Maybe<B2GExporterDeclaration>
  id: Scalars["ID"]["output"]
  lastStatusChange?: Maybe<Scalars["AWSDateTime"]["output"]>
  market?: Maybe<DestinationMarket>
  referencedDocuments: Array<B2GReferencedDocument>
  requestName?: Maybe<Scalars["String"]["output"]>
  requestedOnBehalfOfConsignor?: Maybe<Scalars["Boolean"]["output"]>
  status: ExportCertificateStatus
}

export type B2GExportCertificateRequestInput = {
  certificateDelivery?: InputMaybe<B2GCertificateDeliveryInput>
  certificateType?: InputMaybe<CertificateType>
  commodity?: InputMaybe<Scalars["String"]["input"]>
  consignment?: InputMaybe<B2GConsignmentInput>
  destination?: InputMaybe<B2GCountryInput>
  exporterDeclaration?: InputMaybe<B2GExporterDeclarationInput>
  id?: InputMaybe<Scalars["ID"]["input"]>
  partyId?: InputMaybe<Scalars["String"]["input"]>
  referencedDocuments?: InputMaybe<Array<B2GReferencedDocumentInput>>
  requestName?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GExporterDeclaration = {
  __typename?: "B2GExporterDeclaration"
  date: Scalars["AWSDateTime"]["output"]
  name: Scalars["String"]["output"]
}

export type B2GExporterDeclarationInput = {
  date: Scalars["AWSDateTime"]["input"]
  name: Scalars["String"]["input"]
}

export type B2GLocation = {
  __typename?: "B2GLocation"
  id?: Maybe<Scalars["ID"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
}

export type B2GLocationInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GNote = {
  __typename?: "B2GNote"
  content: Scalars["String"]["output"]
  contentCode?: Maybe<Scalars["String"]["output"]>
  subject?: Maybe<Scalars["String"]["output"]>
  subjectCode?: Maybe<Scalars["String"]["output"]>
}

export type B2GNoteInput = {
  content: Scalars["String"]["input"]
  contentCode?: InputMaybe<Scalars["String"]["input"]>
  subject?: InputMaybe<Scalars["String"]["input"]>
  subjectCode?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GPackage = {
  __typename?: "B2GPackage"
  levelCode?: Maybe<Scalars["String"]["output"]>
  nominalGrossVolume?: Maybe<Scalars["String"]["output"]>
  nominalGrossWeight?: Maybe<Scalars["String"]["output"]>
  packaging?: Maybe<B2GPackage>
  physicalShippingMarks: Array<Scalars["String"]["output"]>
  quantity?: Maybe<Scalars["String"]["output"]>
  typeCode?: Maybe<NamedCode>
}

export type B2GPackageInput = {
  levelCode?: InputMaybe<Scalars["String"]["input"]>
  nominalGrossVolume?: InputMaybe<Scalars["String"]["input"]>
  nominalGrossWeight?: InputMaybe<Scalars["String"]["input"]>
  packaging?: InputMaybe<B2GPackageInput>
  physicalShippingMarks?: InputMaybe<Array<Scalars["String"]["input"]>>
  quantity?: InputMaybe<Scalars["String"]["input"]>
  typeCode?: InputMaybe<NamedCodeInput>
}

export type B2GParty = {
  __typename?: "B2GParty"
  definedContacts?: Maybe<Array<B2GContact>>
  id?: Maybe<Scalars["ID"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  partyId?: Maybe<Scalars["String"]["output"]>
  specifiedAddress?: Maybe<B2GAddress>
  specifiedPerson?: Maybe<B2GPerson>
}

export type B2GPartyInput = {
  definedContacts?: InputMaybe<Array<B2GContactInput>>
  id?: InputMaybe<Scalars["ID"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
  partyId?: InputMaybe<Scalars["String"]["input"]>
  specifiedAddress?: InputMaybe<B2GAddressInput>
  specifiedPerson?: InputMaybe<B2GPersonInput>
}

export type B2GPeriod = {
  __typename?: "B2GPeriod"
  duration?: Maybe<Scalars["String"]["output"]>
  end?: Maybe<Scalars["AWSDateTime"]["output"]>
  start?: Maybe<Scalars["AWSDateTime"]["output"]>
}

export type B2GPeriodInput = {
  duration?: InputMaybe<Scalars["String"]["input"]>
  end?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  start?: InputMaybe<Scalars["AWSDateTime"]["input"]>
}

export type B2GPerson = {
  __typename?: "B2GPerson"
  attainedQualifications: Array<B2GQualification>
  name?: Maybe<Scalars["String"]["output"]>
}

export type B2GPersonInput = {
  attainedQualifications?: InputMaybe<Array<B2GQualificationInput>>
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GPremise = {
  __typename?: "B2GPremise"
  id: Scalars["ID"]["output"]
  name?: Maybe<Scalars["String"]["output"]>
}

export type B2GPremiseInput = {
  id: Scalars["ID"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GProcess = {
  __typename?: "B2GProcess"
  applicableProcessCharacteristics: Array<B2GProcessCharacteristic>
  completionPeriod?: Maybe<B2GPeriod>
  operationCountry?: Maybe<B2GCountry>
  operatorParty?: Maybe<B2GParty>
  typeCode?: Maybe<NamedCode>
}

export type B2GProcessCharacteristic = {
  __typename?: "B2GProcessCharacteristic"
  description?: Maybe<Scalars["String"]["output"]>
  maximum?: Maybe<Scalars["String"]["output"]>
  minimum?: Maybe<Scalars["String"]["output"]>
  typeCode?: Maybe<NamedCode>
  value?: Maybe<Scalars["String"]["output"]>
}

export type B2GProcessCharacteristicInput = {
  description?: InputMaybe<Scalars["String"]["input"]>
  maximum?: InputMaybe<Scalars["String"]["input"]>
  minimum?: InputMaybe<Scalars["String"]["input"]>
  typeCode?: InputMaybe<NamedCodeInput>
  value?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GProcessInput = {
  applicableProcessCharacteristics?: InputMaybe<
    Array<B2GProcessCharacteristicInput>
  >
  completionPeriod?: InputMaybe<B2GPeriodInput>
  operationCountry?: InputMaybe<B2GCountryInput>
  operatorParty?: InputMaybe<B2GPartyInput>
  typeCode?: InputMaybe<NamedCodeInput>
}

export type B2GProduct = {
  __typename?: "B2GProduct"
  additionalInformation: Array<B2GNote>
  appliedProcesses: Array<B2GProcess>
  assertedAuthentications: Array<B2GAuthentication>
  classifications: Array<B2GClassification>
  cnCode?: Maybe<Scalars["String"]["output"]>
  commodity: Scalars["String"]["output"]
  commonName?: Maybe<Scalars["String"]["output"]>
  created: Scalars["AWSDateTime"]["output"]
  createdBy?: Maybe<Scalars["String"]["output"]>
  declarations?: Maybe<Array<B2GDeclaration>>
  description: Scalars["String"]["output"]
  eligibilityCriteriaFailed?: Maybe<Array<B2GEligibilityCriteriaMet>>
  eligibilityCriteriaMet?: Maybe<Array<B2GEligibilityCriteriaMet>>
  eligibilityStatus?: Maybe<Array<B2GEligibilityStatus>>
  events: Array<B2GProductEvent>
  expiry?: Maybe<Scalars["AWSDateTime"]["output"]>
  exporterReference?: Maybe<Scalars["ID"]["output"]>
  finalProcessor?: Maybe<B2GParty>
  grossVolume?: Maybe<Scalars["String"]["output"]>
  grossWeight?: Maybe<Scalars["String"]["output"]>
  hsCode?: Maybe<Scalars["String"]["output"]>
  id: Scalars["ID"]["output"]
  intendedUse: Array<Scalars["String"]["output"]>
  migrationId?: Maybe<Scalars["ID"]["output"]>
  modifiable: Scalars["Boolean"]["output"]
  netVolume?: Maybe<Scalars["String"]["output"]>
  netWeight?: Maybe<Scalars["String"]["output"]>
  parentId?: Maybe<Scalars["ID"]["output"]>
  physicalPackage?: Maybe<B2GPackage>
  premise: B2GPremise
  productNumber?: Maybe<Scalars["String"]["output"]>
  productionBatchIds: Array<Scalars["String"]["output"]>
  referencedDocuments: Array<B2GReferencedDocument>
  scientificName?: Maybe<Scalars["String"]["output"]>
  verifier?: Maybe<B2GParty>
  wine?: Maybe<B2GWineFields>
}

export type B2GProductEligibility = {
  __typename?: "B2GProductEligibility"
  createdAt?: Maybe<Scalars["AWSDateTime"]["output"]>
  criteriaId: Scalars["ID"]["output"]
  effectiveDate?: Maybe<Scalars["AWSDateTime"]["output"]>
  executionArn?: Maybe<Scalars["String"]["output"]>
  expiryDate?: Maybe<Scalars["AWSDateTime"]["output"]>
  id: Scalars["ID"]["output"]
  market: DestinationMarket
  status: B2GEligibilityStatusType
}

export type B2GProductEligibilityInput = {
  createdAt?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  criteriaId: Scalars["ID"]["input"]
  effectiveDate?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  executionArn?: InputMaybe<Scalars["String"]["input"]>
  expiryDate?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  id: Scalars["ID"]["input"]
  market: DestinationMarket
  status: B2GEligibilityStatusType
}

export type B2GProductEvent = {
  __typename?: "B2GProductEvent"
  from: B2GProduct
  id: Scalars["ID"]["output"]
  other?: Maybe<Scalars["String"]["output"]>
  to: B2GProduct
  what: B2GProductEventType
  when: Scalars["AWSDateTime"]["output"]
  where: B2GPremise
}

export enum B2GProductEventType {
  AddedToExportCertificateRequest = "ADDED_TO_EXPORT_CERTIFICATE_REQUEST",
  Amended = "AMENDED",
  Other = "OTHER",
  Processsed = "PROCESSSED",
  Saved = "SAVED",
  Transferred = "TRANSFERRED",
}

export type B2GProductInput = {
  additionalInformation?: InputMaybe<Array<B2GNoteInput>>
  appliedProcesses?: InputMaybe<Array<B2GProcessInput>>
  assertedAuthentications?: InputMaybe<Array<B2GAuthenticationInput>>
  classifications?: InputMaybe<Array<B2GClassificationInput>>
  cnCode?: InputMaybe<Scalars["String"]["input"]>
  commodity: Scalars["String"]["input"]
  commonName?: InputMaybe<Scalars["String"]["input"]>
  created?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  declarations?: InputMaybe<Array<B2GDeclarationInput>>
  description: Scalars["String"]["input"]
  eligibilityCriteriaFailed?: InputMaybe<Array<B2GEligibilityCriteriaMetInput>>
  eligibilityCriteriaMet?: InputMaybe<Array<B2GEligibilityCriteriaMetInput>>
  expiry?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  exporterReference?: InputMaybe<Scalars["ID"]["input"]>
  finalProcessor?: InputMaybe<B2GPartyInput>
  grossVolume?: InputMaybe<Scalars["String"]["input"]>
  grossWeight?: InputMaybe<Scalars["String"]["input"]>
  hsCode?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["ID"]["input"]>
  intendedUse?: InputMaybe<Array<Scalars["String"]["input"]>>
  netVolume?: InputMaybe<Scalars["String"]["input"]>
  netWeight?: InputMaybe<Scalars["String"]["input"]>
  parentId?: InputMaybe<Scalars["ID"]["input"]>
  physicalPackage?: InputMaybe<B2GPackageInput>
  premise: B2GPremiseInput
  productionBatchIds?: InputMaybe<Array<Scalars["String"]["input"]>>
  referencedDocuments?: InputMaybe<Array<B2GReferencedDocumentInput>>
  scientificName?: InputMaybe<Scalars["String"]["input"]>
  verifier?: InputMaybe<B2GPartyInput>
  wine?: InputMaybe<B2GWineFieldsInput>
}

export type B2GQualification = {
  __typename?: "B2GQualification"
  abbreviatedName?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
}

export type B2GQualificationInput = {
  abbreviatedName?: InputMaybe<Scalars["String"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GReferencedDocument = {
  __typename?: "B2GReferencedDocument"
  attachment?: Maybe<ReferencedDocument>
  documentType?: Maybe<Scalars["String"]["output"]>
  information?: Maybe<Scalars["String"]["output"]>
  issued?: Maybe<Scalars["AWSDateTime"]["output"]>
  relationshipTypeCode: Scalars["String"]["output"]
  requestorId?: Maybe<Scalars["ID"]["output"]>
  typeCode?: Maybe<Scalars["String"]["output"]>
}

export type B2GReferencedDocumentInput = {
  attachment: Scalars["ID"]["input"]
  documentType?: InputMaybe<Scalars["String"]["input"]>
  information?: InputMaybe<Scalars["String"]["input"]>
  issued?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  relationshipTypeCode: Scalars["String"]["input"]
  requestorId?: InputMaybe<Scalars["ID"]["input"]>
  typeCode?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GSeal = {
  __typename?: "B2GSeal"
  issuingParty?: Maybe<B2GParty>
  sealId: Scalars["String"]["output"]
}

export type B2GSealInput = {
  issuingParty?: InputMaybe<B2GPartyInput>
  sealId: Scalars["String"]["input"]
}

export type B2GTemperature = {
  __typename?: "B2GTemperature"
  maximum?: Maybe<Scalars["String"]["output"]>
  minimum?: Maybe<Scalars["String"]["output"]>
  value?: Maybe<Scalars["String"]["output"]>
}

export type B2GTemperatureInput = {
  maximum?: InputMaybe<Scalars["String"]["input"]>
  minimum?: InputMaybe<Scalars["String"]["input"]>
  value?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GTradeLineItem = {
  __typename?: "B2GTradeLineItem"
  associatedTransportEquipment: Array<B2GContainer>
  id: Scalars["String"]["output"]
  physicalPackage?: Maybe<B2GPackage>
  product?: Maybe<B2GProduct>
  verified: Scalars["Boolean"]["output"]
}

export type B2GTradeLineItemContainer = {
  __typename?: "B2GTradeLineItemContainer"
  containerId: Scalars["String"]["output"]
  grossVolume?: Maybe<Scalars["String"]["output"]>
  grossWeight?: Maybe<Scalars["String"]["output"]>
  netVolume?: Maybe<Scalars["String"]["output"]>
  netWeight?: Maybe<Scalars["String"]["output"]>
}

export type B2GTradeLineItemContainerInput = {
  containerId: Scalars["String"]["input"]
  grossVolume?: InputMaybe<Scalars["String"]["input"]>
  grossWeight?: InputMaybe<Scalars["String"]["input"]>
  netVolume?: InputMaybe<Scalars["String"]["input"]>
  netWeight?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GTradeLineItemInput = {
  associatedTransportEquipment?: InputMaybe<Array<B2GContainerInput>>
  physicalPackage?: InputMaybe<B2GPackageInput>
  product?: InputMaybe<B2GProductInput>
}

export type B2GTransportMovement = {
  __typename?: "B2GTransportMovement"
  ITDNNumber?: Maybe<Scalars["String"]["output"]>
  mode: TransportModeCode
  reference?: Maybe<Scalars["String"]["output"]>
  registeredCountry?: Maybe<B2GCountry>
  usedTransportMeans?: Maybe<Scalars["String"]["output"]>
}

export type B2GTransportMovementInput = {
  ITDNNumber?: InputMaybe<Scalars["String"]["input"]>
  mode: TransportModeCode
  reference?: InputMaybe<Scalars["String"]["input"]>
  registeredCountry?: InputMaybe<B2GCountryInput>
  usedTransportMeans?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GValidationErrors = {
  __typename?: "B2GValidationErrors"
  error?: Maybe<Scalars["String"]["output"]>
  path?: Maybe<Scalars["String"]["output"]>
  type?: Maybe<B2GError>
}

export type B2GWineConsignment = {
  __typename?: "B2GWineConsignment"
  packaging?: Maybe<B2GPackage>
  shippingFormat?: Maybe<WinePackaging>
}

export type B2GWineConsignmentInput = {
  packaging?: InputMaybe<B2GPackageInput>
  shippingFormat?: InputMaybe<WinePackaging>
}

export type B2GWineFields = {
  __typename?: "B2GWineFields"
  EUCategory?: Maybe<Scalars["String"]["output"]>
  EUIngredients?: Maybe<Array<EuIngredient>>
  alternateWineNames?: Maybe<Array<Scalars["String"]["output"]>>
  availableLitres?: Maybe<Scalars["Float"]["output"]>
  colour?: Maybe<Scalars["String"]["output"]>
  exportedLitres?: Maybe<Scalars["Float"]["output"]>
  geographicIndication?: Maybe<Scalars["ID"]["output"]>
  grapeVariety?: Maybe<Scalars["String"]["output"]>
  mainNamePrefix?: Maybe<Scalars["String"]["output"]>
  mainNameSuffix?: Maybe<Scalars["String"]["output"]>
  package?: Maybe<B2GWinePackage>
  shippingFormat?: Maybe<WinePackaging>
  specialProcess?: Maybe<Scalars["String"]["output"]>
  style?: Maybe<Scalars["String"]["output"]>
  totalPackagedLitres?: Maybe<Scalars["Float"]["output"]>
  vintage?: Maybe<Scalars["String"]["output"]>
  volumeAdjustment?: Maybe<Array<ManualVolumeAdjustment>>
}

export type B2GWineFieldsInput = {
  EUCategory?: InputMaybe<Scalars["String"]["input"]>
  EUIngredients?: InputMaybe<Array<EuIngredientInput>>
  alternateWineNames?: InputMaybe<Array<Scalars["String"]["input"]>>
  colour?: InputMaybe<Scalars["String"]["input"]>
  geographicIndication?: InputMaybe<Scalars["ID"]["input"]>
  grapeVariety?: InputMaybe<Scalars["String"]["input"]>
  mainNamePrefix?: InputMaybe<Scalars["String"]["input"]>
  mainNameSuffix?: InputMaybe<Scalars["String"]["input"]>
  package?: InputMaybe<B2GWinePackageInput>
  shippingFormat?: InputMaybe<WinePackaging>
  specialProcess?: InputMaybe<Scalars["String"]["input"]>
  style?: InputMaybe<Scalars["String"]["input"]>
  vintage?: InputMaybe<Scalars["String"]["input"]>
}

export type B2GWinePackage = {
  __typename?: "B2GWinePackage"
  bottleSize?: Maybe<Scalars["String"]["output"]>
  bottlesPerCase?: Maybe<Scalars["Int"]["output"]>
  cases?: Maybe<Scalars["Int"]["output"]>
  extraBottles?: Maybe<Scalars["Int"]["output"]>
  nameForDocuments: Scalars["String"]["output"]
}

export type B2GWinePackageInput = {
  bottleSize?: InputMaybe<Scalars["String"]["input"]>
  bottlesPerCase?: InputMaybe<Scalars["Int"]["input"]>
  cases?: InputMaybe<Scalars["Int"]["input"]>
  extraBottles?: InputMaybe<Scalars["Int"]["input"]>
  nameForDocuments: Scalars["String"]["input"]
}

export type BottlingRunData = {
  __typename?: "BottlingRunData"
  bottleVolume: Scalars["ID"]["output"]
  bottlesPerCase: Scalars["Int"]["output"]
  cases: Scalars["Int"]["output"]
  date: Scalars["AWSDateTime"]["output"]
  extraBottles?: Maybe<Scalars["Int"]["output"]>
  id: Scalars["ID"]["output"]
  litres: Scalars["Float"]["output"]
  reference: Scalars["String"]["output"]
}

export type BottlingRunDataInput = {
  bottleVolume: Scalars["ID"]["input"]
  bottlesPerCase: Scalars["Int"]["input"]
  cases: Scalars["Int"]["input"]
  date: Scalars["AWSDateTime"]["input"]
  extraBottles?: InputMaybe<Scalars["Int"]["input"]>
  id?: InputMaybe<Scalars["ID"]["input"]>
  reference: Scalars["String"]["input"]
}

export type BottlingRunInput = {
  processId: Scalars["ID"]["input"]
  runs: Array<BottlingRunDataInput>
}

export type BottlingRunResponse = {
  __typename?: "BottlingRunResponse"
  processId: Scalars["ID"]["output"]
  runs: Array<BottlingRunData>
}

export enum BusinessArea {
  Dairy = "DAIRY",
  Honey = "HONEY",
  LiveAnimals = "LIVE_ANIMALS",
  Meat = "MEAT",
  Organics = "ORGANICS",
  Other = "OTHER",
  Plants = "PLANTS",
  Seafood = "SEAFOOD",
  Seed = "SEED",
  Wine = "WINE",
}

export type CancelledProcessInput = {
  cancelledReason?: InputMaybe<Scalars["String"]["input"]>
  processId: Scalars["ID"]["input"]
}

export type CancelledProcessResponse = {
  __typename?: "CancelledProcessResponse"
  cancelledReason?: Maybe<Scalars["String"]["output"]>
  firstResultReportedDate?: Maybe<Scalars["String"]["output"]>
  receipt: ChemistryTestReceipt
  request: ChemistryTestRequest
  status?: Maybe<Scalars["String"]["output"]>
}

export enum CargoTypeClassificationCode {
  CtBallastOnly = "CT_BALLAST_ONLY",
  CtBreakbulk = "CT_BREAKBULK",
  CtContainerized = "CT_CONTAINERIZED",
  CtDeepFrozenCargo = "CT_DEEP_FROZEN_CARGO",
  CtDiplomatic = "CT_DIPLOMATIC",
  CtDocuments = "CT_DOCUMENTS",
  CtEnvironmentalPollutantCargo = "CT_ENVIRONMENTAL_POLLUTANT_CARGO",
  CtFrozenCargo = "CT_FROZEN_CARGO",
  CtGeneralCargo = "CT_GENERAL_CARGO",
  CtHazardousCargo = "CT_HAZARDOUS_CARGO",
  CtHighValueConsignments = "CT_HIGH_VALUE_CONSIGNMENTS",
  CtHouseholdGoodsAndPersonalEffects = "CT_HOUSEHOLD_GOODS_AND_PERSONAL_EFFECTS",
  CtIncompatibleCargo = "CT_INCOMPATIBLE_CARGO",
  CtLiquidCargo = "CT_LIQUID_CARGO",
  CtLowValueDutiableConsignments = "CT_LOW_VALUE_DUTIABLE_CONSIGNMENTS",
  CtLowValueNonDutiableConsignments = "CT_LOW_VALUE_NON_DUTIABLE_CONSIGNMENTS",
  CtMilitary = "CT_MILITARY",
  CtNotHazardousCargo = "CT_NOT_HAZARDOUS_CARGO",
  CtObnoxious = "CT_OBNOXIOUS",
  CtOtherNonContainerized = "CT_OTHER_NON_CONTAINERIZED",
  CtOutOfGauge = "CT_OUT_OF_GAUGE",
  CtPalletized = "CT_PALLETIZED",
  CtRollOnRollOff = "CT_ROLL_ON_ROLL_OFF",
  CtTemperatureControlledCargo = "CT_TEMPERATURE_CONTROLLED_CARGO",
  CtVehicles = "CT_VEHICLES",
}

export enum CertificateType {
  ExportEligibilityStatement = "EXPORT_ELIGIBILITY_STATEMENT",
  FreeSale = "FREE_SALE",
  FreeSaleStatement = "FREE_SALE_STATEMENT",
  LiveAnimals = "LIVE_ANIMALS",
  Organics = "ORGANICS",
  Supplementary = "SUPPLEMENTARY",
}

export type ChemistryTest = {
  chemistryTest: Scalars["String"]["input"]
  result: Scalars["Float"]["input"]
}

export type ChemistryTestInput = {
  attributes: ChemistryTestRequestInput
  productId: Scalars["ID"]["input"]
}

export type ChemistryTestReceipt = {
  __typename?: "ChemistryTestReceipt"
  laboratoryReference?: Maybe<Scalars["String"]["output"]>
  receiptDate?: Maybe<Scalars["AWSDateTime"]["output"]>
  sampleSuitable?: Maybe<Scalars["Boolean"]["output"]>
  unsuitableReason?: Maybe<Scalars["String"]["output"]>
}

export type ChemistryTestRequest = {
  __typename?: "ChemistryTestRequest"
  expectedAlcoholContent?: Maybe<Scalars["Float"]["output"]>
  laboratory: Scalars["ID"]["output"]
  packagingDate?: Maybe<Scalars["AWSDateTime"]["output"]>
  packagingReference?: Maybe<Scalars["String"]["output"]>
  sampleCollectionDate: Scalars["AWSDateTime"]["output"]
  sampleReference: Scalars["String"]["output"]
  testRequired: Scalars["ID"]["output"]
  wineTestReference: Scalars["String"]["output"]
}

export type ChemistryTestRequestInput = {
  expectedAlcoholContent?: InputMaybe<Scalars["Float"]["input"]>
  laboratory: Scalars["String"]["input"]
  packagingDate?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  packagingReference?: InputMaybe<Scalars["String"]["input"]>
  sampleCollectionDate: Scalars["AWSDateTime"]["input"]
  testRequired: Scalars["ID"]["input"]
}

export type ChemistryTestResponse = {
  __typename?: "ChemistryTestResponse"
  cancelledReason?: Maybe<Scalars["String"]["output"]>
  firstResultReportedDate?: Maybe<Scalars["String"]["output"]>
  receipt: ChemistryTestReceipt
  request: ChemistryTestRequest
  status?: Maybe<Scalars["String"]["output"]>
}

export enum ChemistryTestStatus {
  CancellationRequested = "CANCELLATION_REQUESTED",
  Cancelled = "CANCELLED",
  Error = "ERROR",
  Fail = "FAIL",
  InProgress = "IN_PROGRESS",
  Pass = "PASS",
  Requested = "REQUESTED",
  Unsuitable = "UNSUITABLE",
}

export type CombinedPartyTemplate = {
  __typename?: "CombinedPartyTemplate"
  authenticator?: Maybe<SpsPartyTemplate>
  processor?: Maybe<SpsPartyTemplate>
}

export type ConfirmPackagingInput = {
  confirmed: Scalars["Boolean"]["input"]
  disputeReason?: InputMaybe<Scalars["String"]["input"]>
  disputeResolution?: InputMaybe<Scalars["String"]["input"]>
  processId: Scalars["ID"]["input"]
  taskId: Scalars["ID"]["input"]
}

export type ConfirmPackagingResponse = {
  __typename?: "ConfirmPackagingResponse"
  confirmed: Scalars["Boolean"]["output"]
  disputeReason?: Maybe<Scalars["String"]["output"]>
  disputeResolution?: Maybe<Scalars["String"]["output"]>
  processId: Scalars["ID"]["output"]
}

export type ConfirmWinemakingInput = {
  confirmations: Array<WinemakingConfirmationInput>
  processId: Scalars["ID"]["input"]
  taskId: Scalars["ID"]["input"]
}

export enum ConnectionType {
  Direct = "Direct",
  Hub = "Hub",
}

export type CreateTemplateInput = {
  assignedTo?: InputMaybe<Scalars["String"]["input"]>
  businessArea: BusinessArea
  code: Scalars["String"]["input"]
  commodity?: InputMaybe<Scalars["String"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  destinationMarket: DestinationMarket
  displayedDescription?: InputMaybe<Scalars["String"]["input"]>
  effective?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  expire?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  exportCertificateType?: InputMaybe<CertificateType>
  importCertificateType?: InputMaybe<ImportCertificateType>
  majorVersion: Scalars["Int"]["input"]
  minorVersion: Scalars["Int"]["input"]
  newFileUpload?: InputMaybe<Scalars["Boolean"]["input"]>
  reasonForUpdate?: InputMaybe<Scalars["String"]["input"]>
  status: TemplateVersionStatus
  title: Scalars["String"]["input"]
}

export type CustomsPermitNumberUsage = {
  __typename?: "CustomsPermitNumberUsage"
  allocatedInPastThirtyDays: Scalars["Int"]["output"]
  unallocated: Scalars["Int"]["output"]
}

export type DateTemplate = {
  __typename?: "DateTemplate"
  day: IntTemplate
  month: MonthTemplate
  year: IntTemplate
}

export type DateTimeTemplate = {
  __typename?: "DateTimeTemplate"
  date: DateTemplate
  time: TimeTemplate
}

export type DecimalTemplate = {
  __typename?: "DecimalTemplate"
  in_English: Scalars["String"]["output"]
  value: Scalars["String"]["output"]
}

export type DecisionTable = {
  __typename?: "DecisionTable"
  approvedRevision?: Maybe<DecisionTableRevision>
  id: Scalars["ID"]["output"]
  inputColumns: Array<DecisionTableColumn>
  lastRevision?: Maybe<DecisionTableRevision>
  responseColumns: Array<DecisionTableColumn>
  revisions: Array<DecisionTableRevision>
}

export type DecisionTableBaseInput = {
  validFrom?: Maybe<Scalars["AWSDateTime"]["output"]>
  validUntil?: Maybe<Scalars["AWSDateTime"]["output"]>
}

export type DecisionTableColumn = {
  __typename?: "DecisionTableColumn"
  collection?: Maybe<Scalars["String"]["output"]>
  dataType: DecisionTableDataType
  enumeration?: Maybe<Array<Scalars["String"]["output"]>>
  enumerationInfo?: Maybe<Array<EnumerationInfo>>
  header: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  mandatory?: Maybe<Scalars["Boolean"]["output"]>
}

export enum DecisionTableDataType {
  Boolean = "BOOLEAN",
  Enumeration = "ENUMERATION",
  Number = "NUMBER",
  Search = "SEARCH",
  String = "STRING",
  Text = "TEXT",
}

export type DecisionTableExpectation = {
  __typename?: "DecisionTableExpectation"
  description: Scalars["String"]["output"]
  input: RulesInput
  response: RulesResponse
}

export type DecisionTableField = {
  __typename?: "DecisionTableField"
  description: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  label: Scalars["String"]["output"]
  mandatory: Scalars["Boolean"]["output"]
}

export type DecisionTableFilter = {
  q?: InputMaybe<Scalars["String"]["input"]>
}

export type DecisionTableRevision = {
  __typename?: "DecisionTableRevision"
  createdAt: Scalars["AWSDateTime"]["output"]
  description: Scalars["String"]["output"]
  expectations: Array<DecisionTableExpectation>
  id: Scalars["ID"]["output"]
  revisionNumber: Scalars["Int"]["output"]
  rows: Array<DecisionTableRow>
  status: DecisionTableRevisionStatus
  title: Scalars["String"]["output"]
  updatedAt: Scalars["AWSDateTime"]["output"]
  updatedBy: Scalars["String"]["output"]
}

export type DecisionTableRevisionInput = {
  decisionTableId: Scalars["ID"]["input"]
  description: Scalars["String"]["input"]
  expectations: Array<DecisionTableRowInput>
  rows: Array<DecisionTableRowInput>
  title: Scalars["String"]["input"]
}

export enum DecisionTableRevisionStatus {
  Approved = "APPROVED",
  Cancelled = "CANCELLED",
  Draft = "DRAFT",
}

export type DecisionTableRow = {
  __typename?: "DecisionTableRow"
  description: Scalars["String"]["output"]
  input: RulesInput
  response: RulesResponse
  sequence?: Maybe<Scalars["Int"]["output"]>
}

export type DecisionTableRowInput = {
  declarationsInput?: InputMaybe<DeclarationsInput>
  declarationsResponse?: InputMaybe<DeclarationsResponseInput>
  description: Scalars["String"]["input"]
  eligibilityInput?: InputMaybe<EligibilityInput>
  eligibilityResponse?: InputMaybe<EligibilityResponseInput>
  exportCertificateWorkflowInput?: InputMaybe<ExportCertificateWorkflowInput>
  exportCertificateWorkflowResponse?: InputMaybe<ExportCertificateWorkflowResponseInput>
  importConnectionsInput?: InputMaybe<ImportConnectionsInput>
  importConnectionsResponse?: InputMaybe<ImportConnectionsResponseInput>
  productFieldInput?: InputMaybe<ProductFieldInput>
  productFieldResponse?: InputMaybe<ProductFieldResponseInput>
  sequence?: InputMaybe<Scalars["Int"]["input"]>
  validFrom?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  validUntil?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  wineAnalytesTolerancesInput?: InputMaybe<WineAnalytesTolerancesInput>
  wineAnalytesTolerancesResponse?: InputMaybe<WineAnalytesTolerancesResponseRowInput>
  wineProductFieldInput?: InputMaybe<WineProductFieldInput>
}

export type DecisionTableTestResult = {
  __typename?: "DecisionTableTestResult"
  error_message: Scalars["String"]["output"]
  sequence: Scalars["Int"]["output"]
  succes: Scalars["Boolean"]["output"]
}

export type DeclarationFormletFields = {
  __typename?: "DeclarationFormletFields"
  eligibilityCriteria: FormletField
  market: FormletField
  text: FormletField
}

export type DeclarationFormletSection = {
  __typename?: "DeclarationFormletSection"
  fields: DeclarationFormletFields
  id: Scalars["String"]["output"]
  type: FormletFieldType
}

export type DeclarationsInput = {
  commodity?: InputMaybe<Scalars["String"]["input"]>
  destinationMarket?: InputMaybe<DestinationMarket>
  processType?: InputMaybe<Scalars["String"]["input"]>
}

export type DeclarationsResponse = {
  __typename?: "DeclarationsResponse"
  commodity?: Maybe<Scalars["String"]["output"]>
  declaration?: Maybe<Scalars["String"]["output"]>
  destinationMarket?: Maybe<DestinationMarket>
  eligibilityCriteria?: Maybe<Scalars["String"]["output"]>
}

export type DeclarationsResponseInput = {
  declaration?: InputMaybe<Scalars["String"]["input"]>
  eligibilityCriteria?: InputMaybe<Scalars["String"]["input"]>
}

export type DeclarationsType = DecisionTableBaseInput & {
  __typename?: "DeclarationsType"
  commodity?: Maybe<Scalars["String"]["output"]>
  destinationMarket?: Maybe<DestinationMarket>
  processType?: Maybe<Scalars["String"]["output"]>
  validFrom?: Maybe<Scalars["AWSDateTime"]["output"]>
  validUntil?: Maybe<Scalars["AWSDateTime"]["output"]>
}

export enum DestinationMarket {
  Australia = "AUSTRALIA",
  Brazil = "BRAZIL",
  China = "CHINA",
  Eu = "EU",
  GreatBritain = "GREAT_BRITAIN",
  Japan = "JAPAN",
  NotApplicable = "NOT_APPLICABLE",
  Singapore = "SINGAPORE",
  Taiwan = "TAIWAN",
}

export type DraftExportCertificate = {
  __typename?: "DraftExportCertificate"
  executionArn: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
}

export type EStarOrganisation = {
  __typename?: "EStarOrganisation"
  id: Scalars["ID"]["output"]
  name: Scalars["String"]["output"]
}

export type EStarOrganisationFilter = {
  q?: InputMaybe<Scalars["String"]["input"]>
}

export type EuIngredient = {
  __typename?: "EUIngredient"
  allergen: Scalars["Boolean"]["output"]
  eNumber?: Maybe<Scalars["String"]["output"]>
  functionalCategory?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  ingredient: Scalars["String"]["output"]
  useENumber?: Maybe<Scalars["Boolean"]["output"]>
}

export type EuIngredientInput = {
  allergen: Scalars["Boolean"]["input"]
  eNumber?: InputMaybe<Scalars["String"]["input"]>
  functionalCategory?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["ID"]["input"]>
  ingredient: Scalars["String"]["input"]
  useENumber?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type EuIngredientsTemplate = {
  __typename?: "EUIngredientsTemplate"
  as_formatted_paragraph?: Maybe<Scalars["String"]["output"]>
}

export type EligibilityInput = {
  commodity?: InputMaybe<Scalars["String"]["input"]>
  destinationMarket?: InputMaybe<DestinationMarket>
}

export type EligibilityResponse = {
  __typename?: "EligibilityResponse"
  commodity?: Maybe<Scalars["String"]["output"]>
  destinationMarket?: Maybe<DestinationMarket>
  eligibilityCriteria: Scalars["String"]["output"]
}

export type EligibilityResponseInput = {
  eligibilityCriteria: Scalars["String"]["input"]
}

export type EligibilityType = DecisionTableBaseInput & {
  __typename?: "EligibilityType"
  commodity?: Maybe<Scalars["String"]["output"]>
  destinationMarket?: Maybe<DestinationMarket>
  eligibilityCriteria?: Maybe<Scalars["String"]["output"]>
  validFrom?: Maybe<Scalars["AWSDateTime"]["output"]>
  validUntil?: Maybe<Scalars["AWSDateTime"]["output"]>
}

export type EnumerationInfo = {
  __typename?: "EnumerationInfo"
  label: Scalars["String"]["output"]
  value: Scalars["String"]["output"]
}

export type EstarCancelRequest = {
  __typename?: "EstarCancelRequest"
  executionArn: Scalars["ID"]["output"]
}

export type ExportCertificateRequest = {
  __typename?: "ExportCertificateRequest"
  certificate_id?: Maybe<Scalars["String"]["output"]>
  certificate_type: CertificateType
  certifier?: Maybe<SpsAuthentication>
  commodity?: Maybe<Scalars["String"]["output"]>
  consignment: SpsConsignment
  destination_market: DestinationMarket
  id: Scalars["ID"]["output"]
  reason_for_status_change?: Maybe<Scalars["String"]["output"]>
  requestor?: Maybe<Scalars["String"]["output"]>
  requestor_id?: Maybe<Scalars["String"]["output"]>
  state_machine_execution_arn?: Maybe<Scalars["String"]["output"]>
  status: ExportCertificateStatus
  verifiers: Array<SpsAuthentication>
}

export type ExportCertificateRequestInput = {
  certificate_id?: InputMaybe<Scalars["String"]["input"]>
  certificate_type: CertificateType
  certifier?: InputMaybe<SpsAuthenticationInput>
  commodity?: InputMaybe<Scalars["String"]["input"]>
  consignment: SpsConsignmentInput
  destination_market: DestinationMarket
  reason_for_status_change?: InputMaybe<Scalars["String"]["input"]>
  requestor?: InputMaybe<Scalars["String"]["input"]>
  requestor_id?: InputMaybe<Scalars["String"]["input"]>
  status: ExportCertificateStatus
  verifiers?: InputMaybe<Array<SpsAuthenticationInput>>
}

export enum ExportCertificateStatus {
  Amended = "AMENDED",
  Approved = "APPROVED",
  Cancelled = "CANCELLED",
  Draft = "DRAFT",
  PendingReplacement = "PENDING_REPLACEMENT",
  ProvisionallyApproved = "PROVISIONALLY_APPROVED",
  Raised = "RAISED",
  Replaced = "REPLACED",
  RequestedToReplace = "REQUESTED_TO_REPLACE",
  RequestedToResubmit = "REQUESTED_TO_RESUBMIT",
  RequestedToRevoke = "REQUESTED_TO_REVOKE",
  Resubmitted = "RESUBMITTED",
  Revoked = "REVOKED",
  Verified = "VERIFIED",
}

export type ExportCertificateSupportingDocumentsInput = {
  requestor?: InputMaybe<Scalars["String"]["input"]>
  requestor_id: Scalars["ID"]["input"]
}

export type ExportCertificateWorkflowInput = {
  certificateType?: InputMaybe<CertificateType>
}

export type ExportCertificateWorkflowResponse = {
  __typename?: "ExportCertificateWorkflowResponse"
  validatonNeeded: Scalars["Boolean"]["output"]
}

export type ExportCertificateWorkflowResponseInput = {
  validatonNeeded: Scalars["Boolean"]["input"]
}

export type ExportCertificateWorkflowType = DecisionTableBaseInput & {
  __typename?: "ExportCertificateWorkflowType"
  certificateType?: Maybe<CertificateType>
  validFrom?: Maybe<Scalars["AWSDateTime"]["output"]>
  validUntil?: Maybe<Scalars["AWSDateTime"]["output"]>
}

export type Field = {
  __typename?: "Field"
  name?: Maybe<Scalars["String"]["output"]>
  reference?: Maybe<Scalars["String"]["output"]>
  valid?: Maybe<Scalars["Boolean"]["output"]>
}

export type FlexiFillingRunData = {
  __typename?: "FlexiFillingRunData"
  date: Scalars["AWSDateTime"]["output"]
  id: Scalars["ID"]["output"]
  litres: Scalars["Float"]["output"]
  reference: Scalars["String"]["output"]
}

export type FlexiFillingRunDataInput = {
  date: Scalars["AWSDateTime"]["input"]
  id?: InputMaybe<Scalars["ID"]["input"]>
  litres: Scalars["Float"]["input"]
  reference: Scalars["String"]["input"]
}

export type FlexiFillingRunInput = {
  processId: Scalars["ID"]["input"]
  runs: Array<FlexiFillingRunDataInput>
}

export type FlexiFillingRunResponse = {
  __typename?: "FlexiFillingRunResponse"
  processId: Scalars["ID"]["output"]
  runs: Array<FlexiFillingRunData>
}

export type Form = {
  __typename?: "Form"
  declarations: Array<DeclarationFormletSection>
  productDetails: Array<FormletField>
}

export type FormletCollectionFilter = {
  __typename?: "FormletCollectionFilter"
  attribute: Scalars["String"]["output"]
  value: Scalars["String"]["output"]
}

export type FormletField = {
  __typename?: "FormletField"
  charLength?: Maybe<Scalars["Int"]["output"]>
  collection?: Maybe<Scalars["String"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  filter?: Maybe<FormletCollectionFilter>
  id: Scalars["String"]["output"]
  label?: Maybe<Scalars["String"]["output"]>
  nested?: Maybe<Array<Maybe<FormletNestedFields>>>
  numberConstrain?: Maybe<NumberConstrain>
  numberOfLines?: Maybe<Scalars["Int"]["output"]>
  options?: Maybe<Array<Maybe<FormletOption>>>
  readonly?: Maybe<Scalars["Boolean"]["output"]>
  required?: Maybe<Scalars["Boolean"]["output"]>
  sectionFields?: Maybe<Array<Maybe<FormletField>>>
  type?: Maybe<FormletFieldType>
  value?: Maybe<Scalars["String"]["output"]>
}

export enum FormletFieldType {
  Checkbox = "CHECKBOX",
  Date = "DATE",
  LongText = "LONG_TEXT",
  MultilineText = "MULTILINE_TEXT",
  MultisearchTable = "MULTISEARCH_TABLE",
  Nested = "NESTED",
  Number = "NUMBER",
  Parallel = "PARALLEL",
  Party = "PARTY",
  Radio = "RADIO",
  Search = "SEARCH",
  Select = "SELECT",
  ShortText = "SHORT_TEXT",
  ShortTextArray = "SHORT_TEXT_ARRAY",
}

export type FormletNestedFields = {
  __typename?: "FormletNestedFields"
  collection?: Maybe<Scalars["String"]["output"]>
  description: Scalars["String"]["output"]
  id: Scalars["String"]["output"]
  type: FormletFieldType
}

export type FormletOption = {
  __typename?: "FormletOption"
  label: Scalars["String"]["output"]
  value: Scalars["String"]["output"]
}

export type GenerateDraftExportCertificateInput = {
  requestor?: InputMaybe<Scalars["String"]["input"]>
  requestor_id: Scalars["ID"]["input"]
}

export type IDsTemplate = {
  __typename?: "IDsTemplate"
  as_rows: Array<Scalars["String"]["output"]>
  comma_separated: Scalars["String"]["output"]
  new_line_separated: Scalars["String"]["output"]
}

export type IppcHubLogEntry = {
  __typename?: "IPPCHubLogEntry"
  /** @deprecated Use certificateNumber */
  certificateId?: Maybe<Scalars["String"]["output"]>
  certificateNumber?: Maybe<Scalars["String"]["output"]>
  date: Scalars["AWSDateTime"]["output"]
  exportCountryCode: Scalars["String"]["output"]
  hubDeliveryNumber: Scalars["String"]["output"]
  hubTrackingInfo: Scalars["String"]["output"]
  id?: Maybe<Scalars["ID"]["output"]>
  message: Scalars["String"]["output"]
}

export type IppcHubLogInput = {
  /** @deprecated Use certificateNumber */
  certificateId?: InputMaybe<Scalars["String"]["input"]>
  certificateNumber?: InputMaybe<Scalars["String"]["input"]>
  exportCountryCode: Scalars["String"]["input"]
  hubDeliveryNumber: Scalars["String"]["input"]
  hubTrackingInfo: Scalars["String"]["input"]
  message: Scalars["String"]["input"]
}

export type ImportCertificate = {
  __typename?: "ImportCertificate"
  certificate_id: Scalars["String"]["output"]
  /** @deprecated Use consignment.consignee */
  consignee?: Maybe<SpsParty>
  consignment: SpsConsignment
  /** @deprecated Use consignment.consignor */
  consignor?: Maybe<SpsParty>
  /** @deprecated Use exchanged_document.referenced_documents */
  embeddedDocument: Array<ImportCertificateEmbeddedDocument>
  exchanged_document: SpsExchangedDocument
  export_country: SpsCountry
  id: Scalars["ID"]["output"]
  /** @deprecated Use consignment.import_country */
  import_country: SpsCountry
  /** @deprecated Use exchanged_document.included_notes */
  included_notes: Array<SpsNote>
  ippcLogs: Array<IppcHubLogEntry>
  issued?: Maybe<Scalars["AWSDateTime"]["output"]>
  /** @deprecated Use exchanged_document.issuer */
  issuer?: Maybe<SpsParty>
  items: Array<SpsTradeLineItem>
  /** @deprecated Use consignment.main_carriage_transport_movements */
  last_transport_movement?: Maybe<SpsTransportMovement>
  /** @deprecated Use consignment.loading_base_port_location */
  loading_base_port_location?: Maybe<SpsLocation>
  logs: Array<ImportCertificateLogMessage>
  /** @deprecated Use consignment.main_carriage_transport_movements */
  main_carriage_transport_movements?: Maybe<Array<SpsTransportMovement>>
  pdf_generated: PdfGenerationStatus
  /** @deprecated Use exchanged_document.referenced_documents */
  referenced_documents: Array<SpsReferencedDocument>
  replaced_by?: Maybe<ImportCertificate>
  replacement_reason?: Maybe<Scalars["String"]["output"]>
  replacing?: Maybe<ImportCertificate>
  replacing_certificate_number?: Maybe<Scalars["String"]["output"]>
  /** @deprecated Use exchanged_document.signatories */
  signatories: Array<SpsAuthentication>
  status: ImportCertificateStatus
  status_code: Scalars["Int"]["output"]
  /** @deprecated Use consignment.transit_countries */
  transit_countries?: Maybe<Array<SpsCountry>>
  type_code: Scalars["Int"]["output"]
  type_name?: Maybe<Scalars["String"]["output"]>
  /** @deprecated Use consignment.unloading_base_port_location */
  unloading_base_port_location?: Maybe<SpsLocation>
  /** @deprecated Use consignment.utilized_transport_equipment */
  utilized_transport_equipment: Array<SpsTransportEquipment>
}

export type ImportCertificateEmbeddedDocument = {
  __typename?: "ImportCertificateEmbeddedDocument"
  description?: Maybe<Scalars["String"]["output"]>
  documentID: Scalars["String"]["output"]
  file_name: Scalars["String"]["output"]
  file_size?: Maybe<Scalars["String"]["output"]>
  file_type: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  url?: Maybe<Scalars["String"]["output"]>
}

export type ImportCertificateExtractItem = {
  __typename?: "ImportCertificateExtractItem"
  code: Scalars["String"]["output"]
  items?: Maybe<Array<ImportCertificateExtractItem>>
  label: Scalars["String"]["output"]
  value: Scalars["String"]["output"]
}

export type ImportCertificateExtractMeasurement = {
  __typename?: "ImportCertificateExtractMeasurement"
  code: Scalars["String"]["output"]
  label: Scalars["String"]["output"]
  measures?: Maybe<Array<ImportCertificateExtractItem>>
  value: Scalars["String"]["output"]
}

export type ImportCertificateExtractTemplate = {
  __typename?: "ImportCertificateExtractTemplate"
  as_items?: Maybe<Scalars["String"]["output"]>
}

export type ImportCertificateIntegration = {
  __typename?: "ImportCertificateIntegration"
  countryCode: Scalars["String"]["output"]
  response?: Maybe<ImportCertificateIntegrationResponse>
}

export enum ImportCertificateIntegrationResponse {
  Accept = "Accept",
  DeliveredNotReadable = "DeliveredNotReadable",
  Ignore = "Ignore",
}

export type ImportCertificateLogMessage = {
  __typename?: "ImportCertificateLogMessage"
  createdBy: Scalars["String"]["output"]
  date?: Maybe<Scalars["AWSDateTime"]["output"]>
  id: Scalars["ID"]["output"]
  message: Scalars["String"]["output"]
  relatedCertificate?: Maybe<ImportCertificate>
  status: ImportCertificateLogStatus
}

export type ImportCertificateLogMessageInput = {
  id: Scalars["ID"]["input"]
  message: Scalars["String"]["input"]
  relatedCertificateId?: InputMaybe<Scalars["ID"]["input"]>
  relatedCertificateNumber?: InputMaybe<Scalars["String"]["input"]>
  status: ImportCertificateLogStatus
}

export enum ImportCertificateLogStatus {
  Acknowledged = "ACKNOWLEDGED",
  Issued = "ISSUED",
  PdfDownloaded = "PDF_DOWNLOADED",
  Rejected = "REJECTED",
  Replaced = "REPLACED",
  ReplacementRequested = "REPLACEMENT_REQUESTED",
  Withdrawn = "WITHDRAWN",
}

export type ImportCertificateMeasurementTemplate = {
  __typename?: "ImportCertificateMeasurementTemplate"
  as_new_lines?: Maybe<Scalars["String"]["output"]>
}

export type ImportCertificatePackagingTemplate = {
  __typename?: "ImportCertificatePackagingTemplate"
  as_new_lines?: Maybe<Scalars["String"]["output"]>
}

export type ImportCertificatePdf = {
  __typename?: "ImportCertificatePdf"
  id: Scalars["ID"]["output"]
  status: PdfGenerationStatus
  url?: Maybe<Scalars["String"]["output"]>
}

export type ImportCertificatePdfInput = {
  id: Scalars["ID"]["input"]
  reason?: InputMaybe<Scalars["String"]["input"]>
}

export enum ImportCertificateStatus {
  Approved = "APPROVED",
  Issued = "ISSUED",
  Rejected = "REJECTED",
  Replaced = "REPLACED",
  ReplacementRequested = "REPLACEMENT_REQUESTED",
  Unrecognised = "UNRECOGNISED",
  Withdrawn = "WITHDRAWN",
}

export type ImportCertificateStatusChangeInput = {
  id: Scalars["ID"]["input"]
  reason: Scalars["String"]["input"]
}

export enum ImportCertificateType {
  ImportedPhytosanitary = "IMPORTED_PHYTOSANITARY",
  ImportedSanitary = "IMPORTED_SANITARY",
  ReexportedPhytosanitary = "REEXPORTED_PHYTOSANITARY",
}

export type ImportCertificateXml = {
  __typename?: "ImportCertificateXml"
  documentName: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  url: Scalars["String"]["output"]
}

export type ImportConnectionsInput = {
  connectionType?: InputMaybe<ConnectionType>
  countryCode?: InputMaybe<Scalars["String"]["input"]>
}

export type ImportConnectionsResponse = {
  __typename?: "ImportConnectionsResponse"
  connectionType: ConnectionType
  countryCode: Scalars["String"]["output"]
  receiving?: Maybe<ImportCertificateIntegrationResponse>
  sending?: Maybe<Scalars["Boolean"]["output"]>
  spsAcknowledgement: Scalars["Boolean"]["output"]
}

export type ImportConnectionsResponseInput = {
  receiving?: InputMaybe<ImportCertificateIntegrationResponse>
  sending?: InputMaybe<Scalars["Boolean"]["input"]>
  spsAcknowledgement: Scalars["Boolean"]["input"]
}

export type ImportConnectionsType = DecisionTableBaseInput & {
  __typename?: "ImportConnectionsType"
  connectionType?: Maybe<ConnectionType>
  countryCode?: Maybe<Scalars["String"]["output"]>
  validFrom?: Maybe<Scalars["AWSDateTime"]["output"]>
  validUntil?: Maybe<Scalars["AWSDateTime"]["output"]>
}

export type IndicatorType = {
  __typename?: "IndicatorType"
  content: Scalars["String"]["output"]
  format?: Maybe<Scalars["String"]["output"]>
}

export type IntTemplate = {
  __typename?: "IntTemplate"
  in_English: Scalars["String"]["output"]
  value: Scalars["String"]["output"]
}

export type ManualVolumeAdjustment = {
  __typename?: "ManualVolumeAdjustment"
  date?: Maybe<Scalars["AWSDateTime"]["output"]>
  litres?: Maybe<Scalars["Int"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  reason?: Maybe<Scalars["String"]["output"]>
}

export type MeasureTemplate = {
  __typename?: "MeasureTemplate"
  mpi_unit?: Maybe<Scalars["String"]["output"]>
  number?: Maybe<Scalars["String"]["output"]>
  three_decimals?: Maybe<Scalars["String"]["output"]>
  /** @deprecated Use three_decimals */
  three_digits?: Maybe<Scalars["String"]["output"]>
  unit?: Maybe<Scalars["String"]["output"]>
  value?: Maybe<Scalars["String"]["output"]>
}

export type MonthTemplate = {
  __typename?: "MonthTemplate"
  abbreviation: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
  number: IntTemplate
}

export type Mutation = {
  __typename?: "Mutation"
  acceptedTermsAndConditions?: Maybe<Scalars["Boolean"]["output"]>
  addBottlingRuns: BottlingRunResponse
  addCustomsPermitNumbers: Scalars["Int"]["output"]
  addFlexiFillingRuns: FlexiFillingRunResponse
  addProduct: B2GProduct
  allocateCustomsPermitNumber: Scalars["String"]["output"]
  appendIPPCHubLog: IppcHubLogEntry
  appendImportCertificateLog: Scalars["Boolean"]["output"]
  approveExportCertificateRequest?: Maybe<ExportCertificateRequest>
  attachTemplateDocument: TemplateUploadUrl
  banWineBatch: Scalars["String"]["output"]
  cancelEstarRequest: EstarCancelRequest
  cancelExportCertificateRequest: B2GExportCertificateRequest
  cancelProcess: CancelledProcessResponse
  cloneTemplate: Template
  completeSensoryProcess: SensoryProcessRequest
  confirmPackaging: ConfirmPackagingResponse
  confirmWinemaking: Scalars["String"]["output"]
  createTemplate: Template
  deleteAllCustomsPermitNumbers: Scalars["Boolean"]["output"]
  deleteExportCertificateRequest: Scalars["Boolean"]["output"]
  deleteExportRequest: Scalars["Boolean"]["output"]
  deleteProduct: Scalars["Boolean"]["output"]
  deleteReferencedDocument: Scalars["ID"]["output"]
  deleteTemplate: Scalars["String"]["output"]
  draftExportCertificateRequest: B2GExportCertificateRequest
  e2eRaiseExportCertificateRequest?: Maybe<B2GExportCertificateRequest>
  forceApproveTemplateVersion: TemplateVersion
  generateDraftExportCertificate: DraftExportCertificate
  getNewTemplateVersionUploadUrl: TemplateUploadUrl
  newPartyApiKey?: Maybe<SearchApiKey>
  newReferencedDocument: NewReferencedDocument
  newSearchApiKey?: Maybe<SearchApiKey>
  raiseExportCertificateRequest?: Maybe<B2GExportCertificateRequest>
  reindexCollection: ReindexResponse
  rejectImportCertificate?: Maybe<ImportCertificate>
  requestChemistryTest: ChemistryTestResponse
  requestConfirmation: RequestConfirmationResponse
  requestReplacementImportCertificate?: Maybe<ImportCertificate>
  resetConfirmationRequest: Scalars["String"]["output"]
  saveExportCertificateRequest: ExportCertificateRequest
  setTemplateVersionApproved: TemplateVersion
  setTemplateVersionStatus: TemplateVersion
  sfChangeExportCertificateRequestStatus?: Maybe<ExportCertificateRequest>
  sfVerifyExportCertificateRequestProducts?: Maybe<
    Array<Maybe<SpsTradeLineItem>>
  >
  splitProduct: Array<B2GProduct>
  startPackaging: ProcessResponse
  startWinemaking: ProcessResponse
  transferProduct: B2GProduct
  updateDecisionTable: DecisionTableRevision
  updateTemplate: Template
}

export type MutationAddBottlingRunsArgs = {
  input: BottlingRunInput
}

export type MutationAddCustomsPermitNumbersArgs = {
  csv: Scalars["String"]["input"]
}

export type MutationAddFlexiFillingRunsArgs = {
  input: FlexiFillingRunInput
}

export type MutationAddProductArgs = {
  ingredients?: InputMaybe<Array<B2GProductInput>>
  product: B2GProductInput
}

export type MutationAllocateCustomsPermitNumberArgs = {
  certificateRequestId: Scalars["String"]["input"]
}

export type MutationAppendIppcHubLogArgs = {
  input: IppcHubLogInput
}

export type MutationAppendImportCertificateLogArgs = {
  input: ImportCertificateLogMessageInput
}

export type MutationApproveExportCertificateRequestArgs = {
  input: ApproveCertificateRequestInput
}

export type MutationAttachTemplateDocumentArgs = {
  templateId: Scalars["ID"]["input"]
}

export type MutationBanWineBatchArgs = {
  input: Array<B2GBanWineBatchInput>
}

export type MutationCancelEstarRequestArgs = {
  reason?: InputMaybe<Scalars["String"]["input"]>
  sampleId: Scalars["ID"]["input"]
}

export type MutationCancelExportCertificateRequestArgs = {
  id: Scalars["ID"]["input"]
}

export type MutationCancelProcessArgs = {
  input: CancelledProcessInput
}

export type MutationCloneTemplateArgs = {
  code: Scalars["String"]["input"]
  parentTemplateId: Scalars["ID"]["input"]
  title: Scalars["String"]["input"]
}

export type MutationCompleteSensoryProcessArgs = {
  input: SensoryProcessInput
}

export type MutationConfirmPackagingArgs = {
  input: ConfirmPackagingInput
}

export type MutationConfirmWinemakingArgs = {
  input: ConfirmWinemakingInput
}

export type MutationCreateTemplateArgs = {
  template: CreateTemplateInput
}

export type MutationDeleteExportCertificateRequestArgs = {
  requestor: Scalars["String"]["input"]
  requestor_id: Scalars["String"]["input"]
}

export type MutationDeleteExportRequestArgs = {
  id: Scalars["ID"]["input"]
}

export type MutationDeleteProductArgs = {
  id: Scalars["ID"]["input"]
}

export type MutationDeleteReferencedDocumentArgs = {
  id: Scalars["ID"]["input"]
}

export type MutationDeleteTemplateArgs = {
  id: Scalars["ID"]["input"]
}

export type MutationDraftExportCertificateRequestArgs = {
  request: B2GExportCertificateRequestInput
}

export type MutationE2eRaiseExportCertificateRequestArgs = {
  id: Scalars["ID"]["input"]
  partyId: Scalars["String"]["input"]
}

export type MutationForceApproveTemplateVersionArgs = {
  templateVersionId: Scalars["ID"]["input"]
}

export type MutationGenerateDraftExportCertificateArgs = {
  input: GenerateDraftExportCertificateInput
}

export type MutationGetNewTemplateVersionUploadUrlArgs = {
  templateId: Scalars["ID"]["input"]
}

export type MutationNewReferencedDocumentArgs = {
  filename?: InputMaybe<Scalars["String"]["input"]>
}

export type MutationRaiseExportCertificateRequestArgs = {
  id: Scalars["ID"]["input"]
}

export type MutationReindexCollectionArgs = {
  collectionName: Scalars["String"]["input"]
  options?: InputMaybe<SearchOptions>
}

export type MutationRejectImportCertificateArgs = {
  input: ImportCertificateStatusChangeInput
}

export type MutationRequestChemistryTestArgs = {
  input: ChemistryTestInput
}

export type MutationRequestConfirmationArgs = {
  input: RequestConfirmationInput
}

export type MutationRequestReplacementImportCertificateArgs = {
  input: ImportCertificateStatusChangeInput
}

export type MutationResetConfirmationRequestArgs = {
  input: ResetConfirmationRequestInput
}

export type MutationSaveExportCertificateRequestArgs = {
  input: ExportCertificateRequestInput
}

export type MutationSetTemplateVersionApprovedArgs = {
  templateVersionId: Scalars["ID"]["input"]
}

export type MutationSetTemplateVersionStatusArgs = {
  status: TemplateVersionStatus
  templateVersionId: Scalars["ID"]["input"]
}

export type MutationSfChangeExportCertificateRequestStatusArgs = {
  input: SfChangeExportCertificateRequestStatusInput
}

export type MutationSfVerifyExportCertificateRequestProductsArgs = {
  input: SfVerifyExportCertificateRequestProductsInput
}

export type MutationSplitProductArgs = {
  fromProduct: Scalars["ID"]["input"]
  toProducts: Array<B2GProductInput>
}

export type MutationStartPackagingArgs = {
  productId: Scalars["ID"]["input"]
}

export type MutationStartWinemakingArgs = {
  productId: Scalars["ID"]["input"]
}

export type MutationTransferProductArgs = {
  id: Scalars["ID"]["input"]
  toPremise: Scalars["ID"]["input"]
}

export type MutationUpdateDecisionTableArgs = {
  input: DecisionTableRevisionInput
}

export type MutationUpdateTemplateArgs = {
  template: UpdateTemplateInput
}

export type NamedCode = {
  __typename?: "NamedCode"
  code: Scalars["String"]["output"]
  name?: Maybe<Scalars["String"]["output"]>
}

export type NamedCodeInput = {
  code: Scalars["String"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type NamedCodeTemplate = {
  __typename?: "NamedCodeTemplate"
  code: Scalars["String"]["output"]
  name?: Maybe<Scalars["String"]["output"]>
}

export type NewReferencedDocument = {
  __typename?: "NewReferencedDocument"
  id: Scalars["ID"]["output"]
  url: Scalars["String"]["output"]
}

export type NumberConstrain = {
  __typename?: "NumberConstrain"
  max?: Maybe<Scalars["Float"]["output"]>
  min?: Maybe<Scalars["Float"]["output"]>
  step: Scalars["Float"]["output"]
}

export enum Optionality {
  Disabled = "DISABLED",
  Optional = "OPTIONAL",
  Readonly = "READONLY",
  Required = "REQUIRED",
}

export type Packaging = {
  __typename?: "Packaging"
  availableLitres: Scalars["Float"]["output"]
  confirmation?: Maybe<PackagingConfirmation>
  exportedLitres?: Maybe<Scalars["Float"]["output"]>
  runs: Array<PackagingRun>
  status: PackagingStatus
  totalPackagedLitres: Scalars["Float"]["output"]
  volumeAdjustment?: Maybe<Array<Maybe<ManualVolumeAdjustment>>>
  wsmp?: Maybe<Scalars["ID"]["output"]>
}

export type PackagingConfirmation = {
  __typename?: "PackagingConfirmation"
  confirmed: Scalars["Boolean"]["output"]
  disputeReason?: Maybe<Scalars["String"]["output"]>
  disputeResolution?: Maybe<Scalars["String"]["output"]>
}

export type PackagingRun = BottlingRunData | FlexiFillingRunData

export enum PackagingStatus {
  Confirmed = "CONFIRMED",
  Disputed = "DISPUTED",
  NotRequested = "NOT_REQUESTED",
  Requested = "REQUESTED",
}

export enum PdfGenerationStatus {
  Generated = "GENERATED",
  InProgress = "IN_PROGRESS",
  NotGenerated = "NOT_GENERATED",
}

export type ProcessAttributesResponse =
  | ChemistryTestResponse
  | Packaging
  | SensoryProcessRequest
  | Winemaking

export type ProcessResponse = {
  __typename?: "ProcessResponse"
  id: Scalars["ID"]["output"]
  task?: Maybe<ProcessTask>
  type: ProductProcessType
}

export enum ProcessStatus {
  Active = "ACTIVE",
  Completed = "COMPLETED",
}

export type ProcessTask = {
  __typename?: "ProcessTask"
  assignedTo?: Maybe<Scalars["String"]["output"]>
  taskId: Scalars["ID"]["output"]
}

export type ProcessType = {
  __typename?: "ProcessType"
  description: Scalars["String"]["output"]
  id: ProductProcessType
}

export type ProductFieldInput = {
  certificateType?: InputMaybe<CertificateType>
  commodity?: InputMaybe<Scalars["String"]["input"]>
  destinationMarket?: InputMaybe<DestinationMarket>
}

export type ProductFieldResponse = {
  __typename?: "ProductFieldResponse"
  certificateType?: Maybe<CertificateType>
  collection?: Maybe<Scalars["String"]["output"]>
  commodity?: Maybe<Scalars["String"]["output"]>
  defaultUnit?: Maybe<Scalars["String"]["output"]>
  defaultValue?: Maybe<Scalars["String"]["output"]>
  destinationMarket?: Maybe<DestinationMarket>
  fieldId: Scalars["ID"]["output"]
  label?: Maybe<Scalars["String"]["output"]>
  listOfValues?: Maybe<Array<Scalars["String"]["output"]>>
  maxStringLength?: Maybe<Scalars["Int"]["output"]>
  required: Optionality
  type?: Maybe<Scalars["String"]["output"]>
}

export type ProductFieldResponseInput = {
  defaultUnit?: InputMaybe<Scalars["String"]["input"]>
  defaultValue?: InputMaybe<Scalars["String"]["input"]>
  fieldId: Scalars["ID"]["input"]
  label?: InputMaybe<Scalars["String"]["input"]>
  listOfValues?: InputMaybe<Array<Scalars["String"]["input"]>>
  maxStringLength?: InputMaybe<Scalars["Int"]["input"]>
  required: Optionality
}

export type ProductFieldType = DecisionTableBaseInput & {
  __typename?: "ProductFieldType"
  certificateType?: Maybe<CertificateType>
  commodity?: Maybe<Scalars["String"]["output"]>
  destinationMarket?: Maybe<DestinationMarket>
  validFrom?: Maybe<Scalars["AWSDateTime"]["output"]>
  validUntil?: Maybe<Scalars["AWSDateTime"]["output"]>
}

export type ProductFieldsInput = {
  certificateType?: InputMaybe<CertificateType>
  commodity?: InputMaybe<Scalars["String"]["input"]>
  country?: InputMaybe<Scalars["String"]["input"]>
  destination?: InputMaybe<DestinationMarket>
}

export type ProductProcessFilterInput = {
  processType?: InputMaybe<ProductProcessType>
  status?: InputMaybe<ProcessStatus>
}

export type ProductProcessResponse = {
  __typename?: "ProductProcessResponse"
  attributes: ProcessAttributesResponse
  confirmationTaskId?: Maybe<Scalars["ID"]["output"]>
  processId: Scalars["ID"]["output"]
  processType: ProductProcessType
  requestTaskId?: Maybe<Scalars["ID"]["output"]>
  resetTaskId?: Maybe<Scalars["ID"]["output"]>
  taskId?: Maybe<Scalars["ID"]["output"]>
}

export enum ProductProcessType {
  BottlingRun = "BOTTLING_RUN",
  Chemistry = "CHEMISTRY",
  FlexiFilling = "FLEXI_FILLING",
  Sensory = "SENSORY",
  WineMaking = "WINE_MAKING",
}

export type ProductProcessesInput = {
  filter?: InputMaybe<ProductProcessFilterInput>
  productId: Scalars["ID"]["input"]
}

export type Query = {
  __typename?: "Query"
  attestations?: Maybe<Array<Scalars["String"]["output"]>>
  availableProcesses: Array<ProcessType>
  availableTemplates?: Maybe<Array<AvailableTemplateFields>>
  certificateTemplateFields?: Maybe<Scalars["AWSJSON"]["output"]>
  certificateTemplateFlatFields: Array<TemplateSection>
  certificateTemplateFlattestFields: Array<TemplateSectionField>
  customsPermitNumberUsage: CustomsPermitNumberUsage
  decisionTable?: Maybe<DecisionTable>
  downloadDocument: TemplateDocumentUrl
  downloadReferencedDocument: ReferencedDocumentUrl
  estarOrganisations: Array<EStarOrganisation>
  executeRules: Array<RulesResponse>
  exportCertificateAsTemplateValues: SpsCertificateTemplate
  exportCertificateRequest?: Maybe<B2GExportCertificateRequest>
  exportCertificateSupportingDocuments: Array<B2GReferencedDocument>
  findApprovedImportCertificate?: Maybe<TemplateVersion>
  getApprovedTemplateVersion?: Maybe<TemplateVersion>
  getExportCertificateRequest?: Maybe<ExportCertificateRequest>
  getFeatureFlags: Array<Scalars["String"]["output"]>
  getImportCertificate?: Maybe<ImportCertificate>
  /** Return a presigned url to upload a document to the given `key` in the given `fileStore`. */
  getPresignedUrl: Scalars["String"]["output"]
  getTemplate?: Maybe<Template>
  getTemplateVersion?: Maybe<TemplateVersion>
  importCertificateEmbeddedDocument: ImportCertificateEmbeddedDocument
  importCertificatePdf: ImportCertificatePdf
  importCertificateXml: ImportCertificateXml
  listImportCertificateIntegrations: Array<ImportCertificateIntegration>
  listImportCertificates: Array<ImportCertificate>
  numberOfDocuments: Scalars["Int"]["output"]
  product: B2GProduct
  productFields: Array<Maybe<ProductFieldResponse>>
  productForm: Array<Maybe<FormletField>>
  productFormlet: Form
  productProcesses: Array<ProductProcessResponse>
  referenceDocuments?: Maybe<Array<Maybe<B2GReferencedDocument>>>
  referencedDocument?: Maybe<ReferencedDocument>
  salesForceApplicationId?: Maybe<Scalars["ID"]["output"]>
  salesForceProductId?: Maybe<SalesForceProductId>
  searchDecisionTables: Array<DecisionTable>
  searchTemplateVersions: Array<TemplateVersion>
  searchTemplates: Array<Template>
  sfExporter: SalesForceParty
  testDecisionTableRevision: Array<DecisionTableTestResult>
  validate?: Maybe<Array<Maybe<B2GValidationErrors>>>
  validateProduct?: Maybe<Array<Maybe<B2GValidationErrors>>>
}

export type QueryAttestationsArgs = {
  certificateType: CertificateType
  commodity: Scalars["String"]["input"]
  destination: Scalars["String"]["input"]
}

export type QueryAvailableProcessesArgs = {
  productId: Scalars["ID"]["input"]
}

export type QueryAvailableTemplatesArgs = {
  commodity: Scalars["String"]["input"]
  countryCode: Scalars["String"]["input"]
}

export type QueryCertificateTemplateFieldsArgs = {
  commodity?: InputMaybe<Scalars["String"]["input"]>
  destinationMarket?: InputMaybe<DestinationMarket>
  exportCertificateType?: InputMaybe<CertificateType>
  importCertificateType?: InputMaybe<ImportCertificateType>
}

export type QueryCertificateTemplateFlatFieldsArgs = {
  commodity?: InputMaybe<Scalars["String"]["input"]>
  destinationMarket?: InputMaybe<DestinationMarket>
  exportCertificateType?: InputMaybe<CertificateType>
  importCertificateType?: InputMaybe<ImportCertificateType>
}

export type QueryCertificateTemplateFlattestFieldsArgs = {
  commodity?: InputMaybe<Scalars["String"]["input"]>
  destinationMarket?: InputMaybe<DestinationMarket>
  exportCertificateType?: InputMaybe<CertificateType>
  importCertificateType?: InputMaybe<ImportCertificateType>
}

export type QueryDecisionTableArgs = {
  id: Scalars["ID"]["input"]
}

export type QueryDownloadDocumentArgs = {
  templateVersionId: Scalars["ID"]["input"]
}

export type QueryDownloadReferencedDocumentArgs = {
  id: Scalars["ID"]["input"]
}

export type QueryEstarOrganisationsArgs = {
  filter?: InputMaybe<EStarOrganisationFilter>
}

export type QueryExecuteRulesArgs = {
  input: RulesExecutionInput
}

export type QueryExportCertificateAsTemplateValuesArgs = {
  id: Scalars["ID"]["input"]
}

export type QueryExportCertificateRequestArgs = {
  id: Scalars["ID"]["input"]
}

export type QueryExportCertificateSupportingDocumentsArgs = {
  input: ExportCertificateSupportingDocumentsInput
}

export type QueryFindApprovedImportCertificateArgs = {
  certificateType: ImportCertificateType
  commodity?: InputMaybe<Scalars["String"]["input"]>
  destinationMarket: DestinationMarket
}

export type QueryGetApprovedTemplateVersionArgs = {
  certificateType: CertificateType
  commodity?: InputMaybe<Scalars["String"]["input"]>
  destinationMarket: DestinationMarket
}

export type QueryGetExportCertificateRequestArgs = {
  id: Scalars["ID"]["input"]
}

export type QueryGetImportCertificateArgs = {
  id: Scalars["ID"]["input"]
}

export type QueryGetPresignedUrlArgs = {
  fileStore: Scalars["String"]["input"]
  key: Scalars["String"]["input"]
}

export type QueryGetTemplateArgs = {
  templateId: Scalars["ID"]["input"]
}

export type QueryGetTemplateVersionArgs = {
  templateVersionId: Scalars["ID"]["input"]
}

export type QueryImportCertificateEmbeddedDocumentArgs = {
  id: Scalars["ID"]["input"]
}

export type QueryImportCertificatePdfArgs = {
  input: ImportCertificatePdfInput
}

export type QueryImportCertificateXmlArgs = {
  id: Scalars["ID"]["input"]
  inline?: InputMaybe<Scalars["Boolean"]["input"]>
}

export type QueryNumberOfDocumentsArgs = {
  collectionName: Scalars["String"]["input"]
}

export type QueryProductArgs = {
  id: Scalars["ID"]["input"]
}

export type QueryProductFieldsArgs = {
  input: ProductFieldsInput
}

export type QueryProductFormArgs = {
  input: ProductFieldsInput
}

export type QueryProductFormletArgs = {
  input: ProductFieldsInput
}

export type QueryProductProcessesArgs = {
  input: ProductProcessesInput
}

export type QueryReferenceDocumentsArgs = {
  exportRequestId: Scalars["ID"]["input"]
}

export type QueryReferencedDocumentArgs = {
  id: Scalars["ID"]["input"]
}

export type QuerySalesForceApplicationIdArgs = {
  requestId: Scalars["ID"]["input"]
}

export type QuerySalesForceProductIdArgs = {
  productId: Scalars["ID"]["input"]
}

export type QuerySearchDecisionTablesArgs = {
  filter?: InputMaybe<DecisionTableFilter>
}

export type QuerySearchTemplateVersionsArgs = {
  filter?: InputMaybe<TemplateFilter>
  optionalFilter?: InputMaybe<TemplateOptionalFilter>
  page?: InputMaybe<Scalars["Int"]["input"]>
  perPage?: InputMaybe<Scalars["Int"]["input"]>
}

export type QuerySearchTemplatesArgs = {
  filter?: InputMaybe<TemplateFilter>
  optionalFilter?: InputMaybe<TemplateOptionalFilter>
  page?: InputMaybe<Scalars["Int"]["input"]>
  perPage?: InputMaybe<Scalars["Int"]["input"]>
}

export type QuerySfExporterArgs = {
  id: Scalars["ID"]["input"]
}

export type QueryTestDecisionTableRevisionArgs = {
  input: DecisionTableRevisionInput
}

export type QueryValidateArgs = {
  id: Scalars["ID"]["input"]
}

export type QueryValidateProductArgs = {
  id: Scalars["ID"]["input"]
}

export type ReferencedDocument = {
  __typename?: "ReferencedDocument"
  filename?: Maybe<Scalars["String"]["output"]>
  id: Scalars["ID"]["output"]
  uploadInProgress: Scalars["Boolean"]["output"]
}

export type ReferencedDocumentUrl = {
  __typename?: "ReferencedDocumentUrl"
  documentName: Scalars["String"]["output"]
  url: Scalars["String"]["output"]
}

export type ReindexResponse = {
  __typename?: "ReindexResponse"
  alias: Scalars["String"]["output"]
  collectionName: Scalars["String"]["output"]
  functionName: Scalars["String"]["output"]
}

export type RequestConfirmationInput = {
  processId: Scalars["ID"]["input"]
  taskId: Scalars["ID"]["input"]
  wsmp: Scalars["ID"]["input"]
}

export type RequestConfirmationResponse = {
  __typename?: "RequestConfirmationResponse"
  processId: Scalars["ID"]["output"]
  wsmp: Scalars["ID"]["output"]
}

export type ResetConfirmationRequestInput = {
  processId: Scalars["ID"]["input"]
  taskId: Scalars["ID"]["input"]
}

export type RulesExecutionInput = {
  decisionTableId: Scalars["ID"]["input"]
  declarations?: InputMaybe<DeclarationsInput>
  eligibility?: InputMaybe<EligibilityInput>
  exportCertificateWorkflow?: InputMaybe<ExportCertificateWorkflowInput>
  importConnections?: InputMaybe<ImportConnectionsInput>
  productField?: InputMaybe<ProductFieldInput>
  wineAnalytesTolerances?: InputMaybe<WineAnalytesTolerancesResponseInput>
  wineProductField?: InputMaybe<WineProductFieldInput>
}

export type RulesInput =
  | DeclarationsType
  | EligibilityType
  | ExportCertificateWorkflowType
  | ImportConnectionsType
  | ProductFieldType
  | WineAnalytesTolerancesType
  | WineProductFieldType

export type RulesResponse =
  | DeclarationsResponse
  | EligibilityResponse
  | ExportCertificateWorkflowResponse
  | ImportConnectionsResponse
  | ProductFieldResponse
  | WineAnalytesTolerancesResponse

export type SpsAddress = {
  __typename?: "SPSAddress"
  city?: Maybe<Scalars["String"]["output"]>
  city_id?: Maybe<Scalars["ID"]["output"]>
  country_id?: Maybe<Scalars["ID"]["output"]>
  country_name?: Maybe<Scalars["String"]["output"]>
  country_subdivision_id?: Maybe<Scalars["ID"]["output"]>
  country_subdivision_name?: Maybe<Scalars["String"]["output"]>
  postcode?: Maybe<Scalars["String"]["output"]>
  postcode_code?: Maybe<Scalars["String"]["output"]>
  street?: Maybe<Scalars["String"]["output"]>
  type_code?: Maybe<AddressTypeCode>
}

export type SpsAddressInput = {
  city?: InputMaybe<Scalars["String"]["input"]>
  city_id?: InputMaybe<Scalars["ID"]["input"]>
  country_id?: InputMaybe<Scalars["ID"]["input"]>
  country_name?: InputMaybe<Scalars["String"]["input"]>
  country_subdivision_id?: InputMaybe<Scalars["ID"]["input"]>
  country_subdivision_name?: InputMaybe<Scalars["String"]["input"]>
  postcode?: InputMaybe<Scalars["String"]["input"]>
  postcode_code?: InputMaybe<Scalars["String"]["input"]>
  street?: InputMaybe<Scalars["String"]["input"]>
  type_code?: InputMaybe<AddressTypeCode>
}

export type SpsAuthentication = {
  __typename?: "SPSAuthentication"
  actual?: Maybe<Scalars["AWSDateTime"]["output"]>
  included_clauses: Array<SpsClause>
  issue_location?: Maybe<SpsLocation>
  location_provider_party?: Maybe<SpsParty>
  provider_party?: Maybe<SpsParty>
  type_code?: Maybe<NamedCode>
}

export type SpsAuthenticationInput = {
  actual?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  included_clauses?: InputMaybe<Array<SpsClauseInput>>
  issue_location?: InputMaybe<SpsLocationInput>
  location_provider_party?: InputMaybe<SpsPartyInput>
  provider_party?: InputMaybe<SpsPartyInput>
  type_code?: InputMaybe<NamedCodeInput>
}

export type SpsCertificate = {
  __typename?: "SPSCertificate"
  consignment: SpsConsignment
  exchanged_document: SpsExchangedDocument
}

export type SpsClassification = {
  __typename?: "SPSClassification"
  class_code?: Maybe<Scalars["String"]["output"]>
  class_name: Scalars["String"]["output"]
  system_id?: Maybe<Scalars["String"]["output"]>
  system_name: Scalars["String"]["output"]
}

export type SpsClassificationInput = {
  class_code?: InputMaybe<Scalars["String"]["input"]>
  class_name: Scalars["String"]["input"]
  system_id?: InputMaybe<Scalars["String"]["input"]>
  system_name: Scalars["String"]["input"]
}

export type SpsClause = {
  __typename?: "SPSClause"
  content?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
}

export type SpsClauseInput = {
  content?: InputMaybe<Scalars["String"]["input"]>
  id?: InputMaybe<Scalars["ID"]["input"]>
}

export type SpsConsignment = {
  __typename?: "SPSConsignment"
  availability_due_date_time?: Maybe<Scalars["AWSDateTime"]["output"]>
  carrier?: Maybe<SpsParty>
  consignee?: Maybe<SpsParty>
  consignee_receipt_location?: Maybe<SpsLocation>
  consignor?: Maybe<SpsParty>
  customs_transit_agent?: Maybe<SpsParty>
  delivery?: Maybe<SpsParty>
  despatch?: Maybe<SpsParty>
  examination_event?: Maybe<SpsEvent>
  export_country?: Maybe<SpsCountry>
  export_exit_date_time?: Maybe<Scalars["AWSDateTime"]["output"]>
  exporter_reference?: Maybe<Scalars["String"]["output"]>
  gross_weight?: Maybe<Scalars["String"]["output"]>
  import_country?: Maybe<SpsCountry>
  items: Array<SpsConsignmentItem>
  loading_base_port_location?: Maybe<SpsLocation>
  main_carriage_transport_movements: Array<SpsTransportMovement>
  reexport_country?: Maybe<SpsCountry>
  ship_stores_indicator?: Maybe<IndicatorType>
  storage_events: Array<SpsEvent>
  transit_countries: Array<SpsCountry>
  transit_locations: Array<SpsLocation>
  unloading_base_port_location?: Maybe<SpsLocation>
  utilized_transport_equipment: Array<SpsTransportEquipment>
}

export type SpsConsignmentInput = {
  availability_due_date_time?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  carrier?: InputMaybe<SpsPartyInput>
  consignee?: InputMaybe<SpsPartyInput>
  consignee_receipt_location?: InputMaybe<SpsLocationInput>
  consignor?: InputMaybe<SpsPartyInput>
  customs_transit_agent?: InputMaybe<SpsPartyInput>
  delivery?: InputMaybe<SpsPartyInput>
  despatch?: InputMaybe<SpsPartyInput>
  examination_event?: InputMaybe<SpsEventInput>
  export_exit_date_time?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  exporter_reference?: InputMaybe<Scalars["String"]["input"]>
  gross_weight?: InputMaybe<Scalars["String"]["input"]>
  import_country?: InputMaybe<SpsCountryInput>
  items?: InputMaybe<Array<SpsConsignmentItemInput>>
  loading_base_port_location?: InputMaybe<SpsLocationInput>
  main_carriage_transport_movements?: InputMaybe<
    Array<SpsTransportMovementInput>
  >
  storage_events?: InputMaybe<Array<SpsEventInput>>
  transit_countries?: InputMaybe<Array<SpsCountryInput>>
  transit_locations?: InputMaybe<Array<SpsLocationInput>>
  unloading_base_port_location?: InputMaybe<SpsLocationInput>
  utilized_transport_equipment?: InputMaybe<Array<SpsTransportEquipmentInput>>
}

export type SpsConsignmentItem = {
  __typename?: "SPSConsignmentItem"
  gross_weight?: Maybe<Scalars["String"]["output"]>
  items: Array<SpsTradeLineItem>
  nature_identification_cargo: Array<CargoTypeClassificationCode>
}

export type SpsConsignmentItemInput = {
  gross_weight?: InputMaybe<Scalars["String"]["input"]>
  items: Array<SpsTradeLineItemInput>
  nature_identification_cargo?: InputMaybe<Array<CargoTypeClassificationCode>>
}

export type SpsContact = {
  __typename?: "SPSContact"
  person_name?: Maybe<Scalars["String"]["output"]>
}

export type SpsContactInput = {
  person_name?: InputMaybe<Scalars["String"]["input"]>
}

export type SpsCountry = {
  __typename?: "SPSCountry"
  id: Scalars["ID"]["output"]
  name?: Maybe<Scalars["String"]["output"]>
  subdivision?: Maybe<SpsCountrySubDivision>
}

export type SpsCountryInput = {
  id: Scalars["ID"]["input"]
  name?: InputMaybe<Scalars["String"]["input"]>
  subdivision?: InputMaybe<SpsCountrySubDivisionInput>
}

export type SpsCountrySubDivision = {
  __typename?: "SPSCountrySubDivision"
  hierarchicalLevelCode?: Maybe<Scalars["Int"]["output"]>
  id?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
}

export type SpsCountrySubDivisionInput = {
  id?: InputMaybe<Scalars["String"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type SpsEvent = {
  __typename?: "SPSEvent"
  occurrence_location?: Maybe<SpsLocation>
}

export type SpsEventInput = {
  occurrence_location?: InputMaybe<SpsLocationInput>
}

export type SpsExchangedDocument = {
  __typename?: "SPSExchangedDocument"
  certificate_id: Scalars["String"]["output"]
  certifier?: Maybe<SpsAuthentication>
  customs_permit_number?: Maybe<Scalars["String"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  included_notes: Array<SpsNote>
  issue_date_time: Scalars["AWSDateTime"]["output"]
  issuer: SpsParty
  name: Scalars["String"]["output"]
  referenced_documents: Array<SpsReferencedDocument>
  signatories: Array<SpsAuthentication>
  status_code: Scalars["String"]["output"]
  type_code: Scalars["String"]["output"]
  verifiers: Array<SpsAuthentication>
}

export type SpsLocation = {
  __typename?: "SPSLocation"
  id?: Maybe<Scalars["ID"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
}

export type SpsLocationInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type SpsNote = {
  __typename?: "SPSNote"
  content: Scalars["String"]["output"]
  content_code?: Maybe<Scalars["String"]["output"]>
  subject?: Maybe<Scalars["String"]["output"]>
  subject_code?: Maybe<Scalars["String"]["output"]>
}

export type SpsNoteInput = {
  content: Scalars["String"]["input"]
  content_code?: InputMaybe<Scalars["String"]["input"]>
  subject?: InputMaybe<Scalars["String"]["input"]>
  subject_code?: InputMaybe<Scalars["String"]["input"]>
}

export type SpsPackage = {
  __typename?: "SPSPackage"
  level_code?: Maybe<Scalars["String"]["output"]>
  nominal_gross_volume?: Maybe<Scalars["String"]["output"]>
  nominal_gross_weight?: Maybe<Scalars["String"]["output"]>
  physical_shipping_marks: Array<Scalars["String"]["output"]>
  quantity?: Maybe<Scalars["String"]["output"]>
  type_code?: Maybe<NamedCode>
}

export type SpsPackageInput = {
  level_code?: InputMaybe<Scalars["String"]["input"]>
  nominal_gross_volume?: InputMaybe<Scalars["String"]["input"]>
  nominal_gross_weight?: InputMaybe<Scalars["String"]["input"]>
  physical_shipping_marks?: InputMaybe<Array<Scalars["String"]["input"]>>
  quantity?: InputMaybe<Scalars["String"]["input"]>
  type_code?: InputMaybe<NamedCodeInput>
}

export type SpsParty = {
  __typename?: "SPSParty"
  defined_contacts: Array<SpsContact>
  name: Scalars["String"]["output"]
  party_id?: Maybe<Scalars["String"]["output"]>
  role_code?: Maybe<Scalars["String"]["output"]>
  specified_address?: Maybe<SpsAddress>
  specified_person?: Maybe<SpsPerson>
  type_code?: Maybe<Scalars["Int"]["output"]>
}

export type SpsPartyInput = {
  defined_contacts?: InputMaybe<Array<SpsContactInput>>
  name: Scalars["String"]["input"]
  party_id?: InputMaybe<Scalars["String"]["input"]>
  role_code?: InputMaybe<Scalars["String"]["input"]>
  specified_address?: InputMaybe<SpsAddressInput>
  specified_person?: InputMaybe<SpsPersonInput>
  type_code?: InputMaybe<Scalars["Int"]["input"]>
}

export type SpsPeriod = {
  __typename?: "SPSPeriod"
  duration?: Maybe<Scalars["String"]["output"]>
  end?: Maybe<Scalars["AWSDateTime"]["output"]>
  start?: Maybe<Scalars["AWSDateTime"]["output"]>
}

export type SpsPeriodInput = {
  duration?: InputMaybe<Scalars["String"]["input"]>
  end?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  start?: InputMaybe<Scalars["AWSDateTime"]["input"]>
}

export type SpsPerson = {
  __typename?: "SPSPerson"
  attained_qualifications: Array<SpsQualification>
  name?: Maybe<Scalars["String"]["output"]>
}

export type SpsPersonInput = {
  attained_qualifications?: InputMaybe<Array<SpsQualificationInput>>
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type SpsProcess = {
  __typename?: "SPSProcess"
  applicable_process_characteristics: Array<SpsProcessCharacteristic>
  completion_period?: Maybe<SpsPeriod>
  operation_country?: Maybe<SpsCountry>
  operator_party?: Maybe<SpsParty>
  type_code?: Maybe<NamedCode>
}

export type SpsProcessCharacteristic = {
  __typename?: "SPSProcessCharacteristic"
  description?: Maybe<Scalars["String"]["output"]>
  maximum?: Maybe<Scalars["String"]["output"]>
  minimum?: Maybe<Scalars["String"]["output"]>
  type_code?: Maybe<NamedCode>
  value?: Maybe<Scalars["String"]["output"]>
}

export type SpsProcessCharacteristicInput = {
  description?: InputMaybe<Scalars["String"]["input"]>
  maximum?: InputMaybe<Scalars["String"]["input"]>
  minimum?: InputMaybe<Scalars["String"]["input"]>
  type_code?: InputMaybe<NamedCodeInput>
  value?: InputMaybe<Scalars["String"]["input"]>
}

export type SpsProcessInput = {
  applicable_process_characteristics?: InputMaybe<
    Array<SpsProcessCharacteristicInput>
  >
  completion_period?: InputMaybe<SpsPeriodInput>
  operation_country?: InputMaybe<SpsCountryInput>
  operator_party?: InputMaybe<SpsPartyInput>
  type_code?: InputMaybe<NamedCodeInput>
}

export type SpsQualification = {
  __typename?: "SPSQualification"
  abbreviated_name?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
}

export type SpsQualificationInput = {
  abbreviated_name?: InputMaybe<Scalars["String"]["input"]>
  name?: InputMaybe<Scalars["String"]["input"]>
}

export type SpsReferencedDocument = {
  __typename?: "SPSReferencedDocument"
  checksum?: Maybe<Scalars["String"]["output"]>
  document_id?: Maybe<Scalars["ID"]["output"]>
  filename?: Maybe<Scalars["String"]["output"]>
  filetype?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
  information?: Maybe<Scalars["String"]["output"]>
  issued?: Maybe<Scalars["AWSDateTime"]["output"]>
  relationship_type_code: Scalars["String"]["output"]
  type_code?: Maybe<Scalars["String"]["output"]>
}

export type SpsSeal = {
  __typename?: "SPSSeal"
  issuing_party?: Maybe<SpsParty>
  maximum_id?: Maybe<Scalars["String"]["output"]>
  seal_id: Scalars["String"]["output"]
}

export type SpsSealInput = {
  issuing_party?: InputMaybe<SpsPartyInput>
  maximum_id?: InputMaybe<Scalars["String"]["input"]>
  seal_id: Scalars["String"]["input"]
}

export type SpsTemperature = {
  __typename?: "SPSTemperature"
  maximum?: Maybe<Scalars["String"]["output"]>
  minimum?: Maybe<Scalars["String"]["output"]>
  type_code?: Maybe<Scalars["String"]["output"]>
  value?: Maybe<Scalars["String"]["output"]>
}

export type SpsTemperatureInput = {
  maximum?: InputMaybe<Scalars["String"]["input"]>
  minimum?: InputMaybe<Scalars["String"]["input"]>
  type_code?: InputMaybe<Scalars["String"]["input"]>
  value?: InputMaybe<Scalars["String"]["input"]>
}

export type SpsTradeLineItem = {
  __typename?: "SPSTradeLineItem"
  additional_information: Array<SpsNote>
  applied_processes: Array<SpsProcess>
  asserted_authentications: Array<SpsAuthentication>
  associated_transport_equipment: Array<SpsTransportEquipment>
  brand_name?: Maybe<Scalars["String"]["output"]>
  classifications: Array<SpsClassification>
  cn_code?: Maybe<Scalars["String"]["output"]>
  comments?: Maybe<Scalars["String"]["output"]>
  commodity?: Maybe<Scalars["String"]["output"]>
  common_name?: Maybe<Scalars["String"]["output"]>
  description: Scalars["String"]["output"]
  expiry_date_time?: Maybe<Scalars["AWSDateTime"]["output"]>
  gross_volume?: Maybe<Scalars["String"]["output"]>
  gross_weight?: Maybe<Scalars["String"]["output"]>
  hs_code?: Maybe<Scalars["String"]["output"]>
  id: Scalars["String"]["output"]
  import_certificate_extract?: Maybe<Array<ImportCertificateExtractItem>>
  import_certificate_measurement?: Maybe<
    Array<ImportCertificateExtractMeasurement>
  >
  import_permit_number?: Maybe<Scalars["String"]["output"]>
  intended_use?: Maybe<Scalars["String"]["output"]>
  net_volume?: Maybe<Scalars["String"]["output"]>
  net_weight?: Maybe<Scalars["String"]["output"]>
  origin_countries?: Maybe<Array<SpsCountry>>
  origin_country?: Maybe<SpsCountry>
  origin_locations: Array<SpsLocation>
  other_quantity?: Maybe<Scalars["String"]["output"]>
  physical_packages: Array<SpsPackage>
  production_batch_id?: Maybe<Scalars["String"]["output"]>
  referenced_documents: Array<SpsReferencedDocument>
  requestor_id?: Maybe<Scalars["String"]["output"]>
  scientific_name?: Maybe<Scalars["String"]["output"]>
  sequence?: Maybe<Scalars["Int"]["output"]>
  verified: Scalars["Boolean"]["output"]
  wine?: Maybe<B2GWineFields>
}

export type SpsTradeLineItemInput = {
  additional_information?: InputMaybe<Array<SpsNoteInput>>
  applied_processes?: InputMaybe<Array<SpsProcessInput>>
  asserted_authentications?: InputMaybe<Array<SpsAuthenticationInput>>
  associated_transport_equipment?: InputMaybe<Array<SpsTransportEquipmentInput>>
  brand_name?: InputMaybe<Scalars["String"]["input"]>
  classifications?: InputMaybe<Array<SpsClassificationInput>>
  commodity?: InputMaybe<Scalars["String"]["input"]>
  common_name?: InputMaybe<Scalars["String"]["input"]>
  description: Scalars["String"]["input"]
  expiry_date_time?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  gross_volume?: InputMaybe<Scalars["String"]["input"]>
  gross_weight?: InputMaybe<Scalars["String"]["input"]>
  hs_code?: InputMaybe<Scalars["String"]["input"]>
  import_permit_number?: InputMaybe<Scalars["String"]["input"]>
  intended_use?: InputMaybe<Scalars["String"]["input"]>
  net_volume?: InputMaybe<Scalars["String"]["input"]>
  net_weight?: InputMaybe<Scalars["String"]["input"]>
  origin_country?: InputMaybe<SpsCountryInput>
  origin_locations?: InputMaybe<Array<SpsLocationInput>>
  physical_packages?: InputMaybe<Array<SpsPackageInput>>
  production_batch_id?: InputMaybe<Scalars["String"]["input"]>
  requestor_id?: InputMaybe<Scalars["String"]["input"]>
  scientific_name?: InputMaybe<Scalars["String"]["input"]>
  sequence?: InputMaybe<Scalars["Int"]["input"]>
}

export type SpsTransportEquipment = {
  __typename?: "SPSTransportEquipment"
  affixed_seals: Array<SpsSeal>
  temperature_settings: Array<SpsTemperature>
  transport_equipment_id: Scalars["String"]["output"]
}

export type SpsTransportEquipmentInput = {
  affixed_seals?: InputMaybe<Array<SpsSealInput>>
  temperature_settings?: InputMaybe<Array<SpsTemperatureInput>>
  transport_equipment_id: Scalars["String"]["input"]
}

export type SpsTransportMovement = {
  __typename?: "SPSTransportMovement"
  mode: TransportModeCode
  mode_name?: Maybe<Scalars["String"]["output"]>
  reference?: Maybe<Scalars["String"]["output"]>
  used_transport_means?: Maybe<Scalars["String"]["output"]>
}

export type SpsTransportMovementInput = {
  mode: TransportModeCode
  reference?: InputMaybe<Scalars["String"]["input"]>
  used_transport_means?: InputMaybe<Scalars["String"]["input"]>
}

export type SpsTransportMovementTemplate = {
  __typename?: "SPSTransportMovementTemplate"
  id?: Maybe<Scalars["ID"]["output"]>
  mode: TransportMovementCodeTemplate
  used_transport_means?: Maybe<Scalars["String"]["output"]>
}

export type SalesForceAddress = {
  __typename?: "SalesForceAddress"
  addressType: Scalars["String"]["output"]
  city: Scalars["String"]["output"]
  country: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  isPrimary: Scalars["Boolean"]["output"]
  postalCode?: Maybe<Scalars["String"]["output"]>
  street: Scalars["String"]["output"]
}

export type SalesForceContact = {
  __typename?: "SalesForceContact"
  emails: Array<SalesForceEmail>
  id: Scalars["ID"]["output"]
  name: Scalars["String"]["output"]
  phones: Array<SalesForcePhone>
  role?: Maybe<Scalars["String"]["output"]>
  title?: Maybe<Scalars["String"]["output"]>
}

export type SalesForceEmail = {
  __typename?: "SalesForceEmail"
  emailAddress: Scalars["String"]["output"]
  emailType: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  isPrimary: Scalars["Boolean"]["output"]
}

export type SalesForceIdentifier = {
  __typename?: "SalesForceIdentifier"
  description: Scalars["String"]["output"]
  id: Scalars["ID"]["output"]
  value: Scalars["String"]["output"]
}

export type SalesForceParty = {
  __typename?: "SalesForceParty"
  addresses: Array<SalesForceAddress>
  contacts: Array<SalesForceContact>
  id: Scalars["String"]["output"]
  identifiers: Array<SalesForceIdentifier>
  name: Scalars["String"]["output"]
  nzbn?: Maybe<Scalars["String"]["output"]>
  tradingAs?: Maybe<Scalars["String"]["output"]>
}

export type SalesForcePhone = {
  __typename?: "SalesForcePhone"
  id: Scalars["ID"]["output"]
  isPrimary: Scalars["Boolean"]["output"]
  phoneType: Scalars["String"]["output"]
  telephoneNumber: Scalars["String"]["output"]
}

export type SalesForceProductId = {
  __typename?: "SalesForceProductId"
  productId: Scalars["ID"]["output"]
  productRevisionId: Scalars["ID"]["output"]
  sfProductId?: Maybe<Scalars["ID"]["output"]>
}

export type SearchApiKey = {
  __typename?: "SearchApiKey"
  apiKey: Scalars["String"]["output"]
  expires: Scalars["AWSDateTime"]["output"]
}

export type SearchOptions = {
  fromDate?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  startWithEmptyCollection: Scalars["Boolean"]["input"]
}

export type SensoryProcessInput = {
  assessmentDate: Scalars["AWSDateTime"]["input"]
  comment?: InputMaybe<Scalars["String"]["input"]>
  disputed: Scalars["Boolean"]["input"]
  disputedReason?: InputMaybe<WineDisputedReason>
  productId: Scalars["ID"]["input"]
  reference: Scalars["String"]["input"]
  winemaker: B2GContactInput
}

export type SensoryProcessRequest = {
  __typename?: "SensoryProcessRequest"
  assessmentDate: Scalars["AWSDateTime"]["output"]
  comment?: Maybe<Scalars["String"]["output"]>
  disputed: Scalars["Boolean"]["output"]
  disputedReason?: Maybe<WineDisputedReason>
  id: Scalars["ID"]["output"]
  productId: Scalars["ID"]["output"]
  reference: Scalars["String"]["output"]
  winemaker: B2GContact
}

export type SfChangeExportCertificateRequestStatusInput = {
  reason_for_status_change?: InputMaybe<Scalars["String"]["input"]>
  requestor?: InputMaybe<Scalars["String"]["input"]>
  requestor_id: Scalars["String"]["input"]
  status: ExportCertificateStatus
}

export type SfVerifyExportCertificateRequestProductsInput = {
  products: Array<SfVerifyProductInput>
  requestor?: InputMaybe<Scalars["String"]["input"]>
}

export type SfVerifyProductInput = {
  comments?: InputMaybe<Scalars["String"]["input"]>
  requestor_product_id: Scalars["String"]["input"]
  verified: Scalars["Boolean"]["input"]
}

export type SpsAddressTemplate = {
  __typename?: "SpsAddressTemplate"
  city?: Maybe<Scalars["String"]["output"]>
  city_id?: Maybe<Scalars["ID"]["output"]>
  country_id?: Maybe<Scalars["ID"]["output"]>
  country_name?: Maybe<Scalars["String"]["output"]>
  country_subdivision_id?: Maybe<Scalars["ID"]["output"]>
  country_subdivision_name?: Maybe<Scalars["String"]["output"]>
  postcode?: Maybe<Scalars["String"]["output"]>
  postcode_code?: Maybe<Scalars["String"]["output"]>
  street?: Maybe<Scalars["String"]["output"]>
  street_with_commas?: Maybe<Scalars["String"]["output"]>
  street_with_line_breaks?: Maybe<Scalars["String"]["output"]>
  type_code?: Maybe<Scalars["String"]["output"]>
  with_line_breaks?: Maybe<Scalars["String"]["output"]>
}

export type SpsAuthenticationTemplate = {
  __typename?: "SpsAuthenticationTemplate"
  actual?: Maybe<DateTimeTemplate>
  ic_label?: Maybe<Scalars["String"]["output"]>
  ic_value?: Maybe<Scalars["String"]["output"]>
  included_clauses?: Maybe<Array<SpsClauseTemplate>>
  issue_location?: Maybe<SpsLocationTemplate>
  location_provider_party?: Maybe<SpsPartyTemplate>
  provider_party?: Maybe<SpsPartyTemplate>
  type_code?: Maybe<NamedCodeTemplate>
}

export type SpsCertificateTemplate = {
  __typename?: "SpsCertificateTemplate"
  consignment: SpsConsignmentTemplate
  exchanged_document: SpsExchangedDocumentTemplate
}

export type SpsClassificationTemplate = {
  __typename?: "SpsClassificationTemplate"
  class_code?: Maybe<Scalars["String"]["output"]>
  class_name: Scalars["String"]["output"]
  system_id?: Maybe<Scalars["String"]["output"]>
  system_name: Scalars["String"]["output"]
}

export type SpsClauseTemplate = {
  __typename?: "SpsClauseTemplate"
  content?: Maybe<Scalars["String"]["output"]>
  id?: Maybe<Scalars["ID"]["output"]>
}

export type SpsConsignmentItemTemplate = {
  __typename?: "SpsConsignmentItemTemplate"
  gross_weight?: Maybe<Scalars["String"]["output"]>
  items: Array<SpsTradeLineItemTemplate>
  nature_identification_cargo?: Maybe<Array<Scalars["String"]["output"]>>
}

export type SpsConsignmentTemplate = {
  __typename?: "SpsConsignmentTemplate"
  authentication_parties?: Maybe<Array<SpsPartyTemplate>>
  availability_due_date_time?: Maybe<DateTimeTemplate>
  carrier?: Maybe<SpsPartyTemplate>
  consignee?: Maybe<SpsPartyTemplate>
  consignee_receipt_location?: Maybe<SpsLocationTemplate>
  consignor?: Maybe<SpsPartyTemplate>
  customs_transit_agent?: Maybe<SpsPartyTemplate>
  delivery?: Maybe<SpsPartyTemplate>
  despatch?: Maybe<SpsPartyTemplate>
  examination_event?: Maybe<SpsEventTemplate>
  export_country?: Maybe<SpsCountryTemplate>
  export_exit_date_time?: Maybe<DateTimeTemplate>
  gross_weight?: Maybe<MeasureTemplate>
  id?: Maybe<Scalars["ID"]["output"]>
  import_country?: Maybe<SpsCountryTemplate>
  items: Array<SpsConsignmentItemTemplate>
  loading_base_port_location?: Maybe<SpsLocationTemplate>
  main_carriage_transport_movements?: Maybe<Array<SpsTransportMovementTemplate>>
  party_table?: Maybe<Array<CombinedPartyTemplate>>
  processor_parties?: Maybe<Array<SpsPartyTemplate>>
  reexport_country?: Maybe<SpsCountryTemplate>
  storage_events?: Maybe<Array<SpsEventTemplate>>
  transit_countries?: Maybe<Array<SpsCountryTemplate>>
  transit_locations?: Maybe<Array<SpsLocationTemplate>>
  unique_production_batch_ids?: Maybe<IDsTemplate>
  unique_seal_number_ids: Scalars["String"]["output"]
  unique_transport_equipment_ids?: Maybe<IDsTemplate>
  unloading_base_port_location?: Maybe<SpsLocationTemplate>
  utilized_transport_equipment?: Maybe<Array<SpsTransportEquipmentTemplate>>
  verifiers?: Maybe<Array<SpsAuthenticationTemplate>>
}

export type SpsContactTemplate = {
  __typename?: "SpsContactTemplate"
  person_name?: Maybe<Scalars["String"]["output"]>
}

export type SpsCountrySubDivisionTemplate = {
  __typename?: "SpsCountrySubDivisionTemplate"
  id?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
}

export type SpsCountryTemplate = {
  __typename?: "SpsCountryTemplate"
  id: Scalars["ID"]["output"]
  name?: Maybe<Scalars["String"]["output"]>
  subdivision?: Maybe<SpsCountrySubDivisionTemplate>
}

export type SpsEventTemplate = {
  __typename?: "SpsEventTemplate"
  occurrence_location?: Maybe<SpsLocationTemplate>
}

export type SpsExchangedDocumentTemplate = {
  __typename?: "SpsExchangedDocumentTemplate"
  additional_declarations?: Maybe<Scalars["String"]["output"]>
  additional_information?: Maybe<Scalars["String"]["output"]>
  additional_official_information?: Maybe<Scalars["String"]["output"]>
  certifier?: Maybe<SpsAuthenticationTemplate>
  customs_permit_number?: Maybe<Scalars["String"]["output"]>
  date_of_inspection?: Maybe<DateTimeTemplate>
  description?: Maybe<Scalars["String"]["output"]>
  distinguishing_marks?: Maybe<Scalars["String"]["output"]>
  financial_liability?: Maybe<Scalars["String"]["output"]>
  id: Scalars["ID"]["output"]
  import_permit?: Maybe<Scalars["String"]["output"]>
  issue_date_time?: Maybe<DateTimeTemplate>
  issuer?: Maybe<SpsPartyTemplate>
  name?: Maybe<Scalars["String"]["output"]>
  replacement_certificate?: Maybe<SpsReplacementCertificateTemplate>
  signatories?: Maybe<Array<Maybe<SpsAuthenticationTemplate>>>
  status_code?: Maybe<Scalars["String"]["output"]>
  status_name?: Maybe<Scalars["String"]["output"]>
  type_code?: Maybe<Scalars["String"]["output"]>
  type_name?: Maybe<Scalars["String"]["output"]>
}

export type SpsLocationTemplate = {
  __typename?: "SpsLocationTemplate"
  id?: Maybe<Scalars["ID"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
}

export type SpsNoteTemplate = {
  __typename?: "SpsNoteTemplate"
  content?: Maybe<Scalars["String"]["output"]>
  content_code?: Maybe<Scalars["String"]["output"]>
  subject?: Maybe<Scalars["String"]["output"]>
  subject_code?: Maybe<Scalars["String"]["output"]>
}

export type SpsPackageTemplate = {
  __typename?: "SpsPackageTemplate"
  level_code?: Maybe<Scalars["String"]["output"]>
  nominal_gross_volume?: Maybe<MeasureTemplate>
  nominal_gross_weight?: Maybe<MeasureTemplate>
  physical_shipping_marks?: Maybe<Array<Scalars["String"]["output"]>>
  quantity?: Maybe<DecimalTemplate>
  type_code?: Maybe<NamedCodeTemplate>
}

export type SpsPartyTemplate = {
  __typename?: "SpsPartyTemplate"
  defined_contacts?: Maybe<Array<SpsContactTemplate>>
  id?: Maybe<Scalars["ID"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  role_code?: Maybe<Scalars["String"]["output"]>
  specified_address?: Maybe<SpsAddressTemplate>
  specified_person?: Maybe<SpsPersonTemplate>
  type_code?: Maybe<Scalars["String"]["output"]>
}

export type SpsPeriodTemplate = {
  __typename?: "SpsPeriodTemplate"
  duration?: Maybe<MeasureTemplate>
  end?: Maybe<DateTimeTemplate>
  start?: Maybe<DateTimeTemplate>
}

export type SpsPersonTemplate = {
  __typename?: "SpsPersonTemplate"
  attained_qualifications?: Maybe<Array<SpsQualificationTemplate>>
  name?: Maybe<Scalars["String"]["output"]>
}

export type SpsProcessCharacteristicTemplate = {
  __typename?: "SpsProcessCharacteristicTemplate"
  description?: Maybe<Scalars["String"]["output"]>
  maximum?: Maybe<MeasureTemplate>
  minimum?: Maybe<MeasureTemplate>
  type_code?: Maybe<NamedCodeTemplate>
  value?: Maybe<MeasureTemplate>
}

export type SpsProcessTemplate = {
  __typename?: "SpsProcessTemplate"
  applicable_process_characteristics?: Maybe<
    Array<SpsProcessCharacteristicTemplate>
  >
  completion_period?: Maybe<SpsPeriodTemplate>
  operation_country?: Maybe<SpsCountryTemplate>
  operator_party?: Maybe<SpsPartyTemplate>
  type_code?: Maybe<NamedCodeTemplate>
}

export type SpsQualificationTemplate = {
  __typename?: "SpsQualificationTemplate"
  abbreviated_name?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
}

export type SpsReplacementCertificateTemplate = {
  __typename?: "SpsReplacementCertificateTemplate"
  additional_declaration?: Maybe<Scalars["String"]["output"]>
  replaced_certificate_issue_date?: Maybe<DateTimeTemplate>
  replaced_certificate_number?: Maybe<Scalars["String"]["output"]>
  replacement_reason?: Maybe<Scalars["String"]["output"]>
}

export type SpsSealTemplate = {
  __typename?: "SpsSealTemplate"
  id: Scalars["String"]["output"]
  issuing_party?: Maybe<SpsPartyTemplate>
  maximum_id?: Maybe<Scalars["String"]["output"]>
}

export type SpsTemperatureTemplate = {
  __typename?: "SpsTemperatureTemplate"
  maximum?: Maybe<MeasureTemplate>
  minimum?: Maybe<MeasureTemplate>
  type_code?: Maybe<Scalars["String"]["output"]>
  value?: Maybe<MeasureTemplate>
}

export type SpsTradeLineItemTemplate = {
  __typename?: "SpsTradeLineItemTemplate"
  additional_information?: Maybe<Array<SpsNoteTemplate>>
  applied_processes?: Maybe<Array<SpsProcessTemplate>>
  asserted_authentications?: Maybe<Array<SpsAuthenticationTemplate>>
  associated_transport_equipment?: Maybe<Array<SpsTransportEquipmentTemplate>>
  brand_name?: Maybe<Scalars["String"]["output"]>
  classifications?: Maybe<Array<SpsClassificationTemplate>>
  cn_code?: Maybe<Scalars["String"]["output"]>
  commodity?: Maybe<Scalars["String"]["output"]>
  common_name?: Maybe<Scalars["String"]["output"]>
  description?: Maybe<Scalars["String"]["output"]>
  expiry_date_time?: Maybe<DateTimeTemplate>
  gross_volume?: Maybe<MeasureTemplate>
  gross_weight?: Maybe<MeasureTemplate>
  hs_code?: Maybe<Scalars["String"]["output"]>
  import_certificate_extract?: Maybe<ImportCertificateExtractTemplate>
  import_certificate_measurement?: Maybe<ImportCertificateMeasurementTemplate>
  import_permit_number?: Maybe<Scalars["String"]["output"]>
  intended_use?: Maybe<Scalars["String"]["output"]>
  net_volume?: Maybe<MeasureTemplate>
  net_weight?: Maybe<MeasureTemplate>
  origin_country?: Maybe<SpsCountryTemplate>
  origin_locations?: Maybe<Array<SpsLocationTemplate>>
  packaging?: Maybe<ImportCertificatePackagingTemplate>
  physical_packages?: Maybe<Array<SpsPackageTemplate>>
  production_batch_id?: Maybe<Scalars["String"]["output"]>
  scientific_name?: Maybe<Scalars["String"]["output"]>
  sequence?: Maybe<Scalars["String"]["output"]>
  treatments?: Maybe<TreatmentTemplate>
  wine?: Maybe<WineFieldsTemplate>
}

export type SpsTransportEquipmentTemplate = {
  __typename?: "SpsTransportEquipmentTemplate"
  affixed_seals?: Maybe<Array<SpsSealTemplate>>
  id: Scalars["String"]["output"]
  temperature_settings?: Maybe<Array<SpsTemperatureTemplate>>
}

export type Template = {
  __typename?: "Template"
  approvedVersion?: Maybe<TemplateVersion>
  id: Scalars["ID"]["output"]
  importantVersions?: Maybe<Array<Maybe<TemplateVersion>>>
  lastVersion: TemplateVersion
  versions?: Maybe<Array<Maybe<TemplateVersion>>>
}

export type TemplateDocumentUrl = {
  __typename?: "TemplateDocumentUrl"
  documentName: Scalars["String"]["output"]
  url: Scalars["String"]["output"]
}

export type TemplateFilter = {
  q?: InputMaybe<Scalars["String"]["input"]>
}

export type TemplateHistory = {
  __typename?: "TemplateHistory"
  fieldName: Scalars["String"]["output"]
  from: Scalars["String"]["output"]
  to: Scalars["String"]["output"]
  updatedAt: Scalars["AWSDateTime"]["output"]
  updatedBy: Scalars["String"]["output"]
  version: Scalars["String"]["output"]
}

export type TemplateOptionalFilter = {
  commodity?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>
  destinationMarket?: InputMaybe<Array<InputMaybe<DestinationMarket>>>
  effectiveDate?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  effectiveDateEnd?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  effectiveDateStart?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  expiryDate?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  expiryDateEnd?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  expiryDateStart?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  lastEditedDate?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  lastEditedDateEnd?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  lastEditedDateStart?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  status?: InputMaybe<Array<InputMaybe<TemplateVersionStatus>>>
}

export type TemplateSection = {
  __typename?: "TemplateSection"
  fields: Array<TemplateSectionField>
  name: Scalars["String"]["output"]
}

export type TemplateSectionField = {
  __typename?: "TemplateSectionField"
  field: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
}

export type TemplateUploadUrl = {
  __typename?: "TemplateUploadUrl"
  lastVersion: TemplateVersion
  url: Scalars["String"]["output"]
}

export type TemplateVersion = {
  __typename?: "TemplateVersion"
  assignedTo?: Maybe<Scalars["String"]["output"]>
  businessArea: BusinessArea
  code: Scalars["String"]["output"]
  commodity?: Maybe<Scalars["String"]["output"]>
  createdAt: Scalars["AWSDateTime"]["output"]
  description?: Maybe<Scalars["String"]["output"]>
  destinationMarket: DestinationMarket
  displayedDescription?: Maybe<Scalars["String"]["output"]>
  documentId?: Maybe<Scalars["String"]["output"]>
  documentRevision?: Maybe<TemplateVersion>
  editable?: Maybe<Scalars["Boolean"]["output"]>
  effective?: Maybe<Scalars["AWSDateTime"]["output"]>
  expire?: Maybe<Scalars["AWSDateTime"]["output"]>
  exportCertificateType?: Maybe<CertificateType>
  fields?: Maybe<Array<Maybe<Field>>>
  history?: Maybe<Array<TemplateHistory>>
  id: Scalars["ID"]["output"]
  importCertificateType?: Maybe<ImportCertificateType>
  majorVersion: Scalars["Int"]["output"]
  minorVersion: Scalars["Int"]["output"]
  newFileUpload: Scalars["Boolean"]["output"]
  reasonForUpdate?: Maybe<Scalars["String"]["output"]>
  s3key?: Maybe<Scalars["String"]["output"]>
  s3uri?: Maybe<Scalars["String"]["output"]>
  status: TemplateVersionStatus
  templateId: Scalars["String"]["output"]
  title: Scalars["String"]["output"]
  updatedAt: Scalars["AWSDateTime"]["output"]
  updatedBy: Scalars["String"]["output"]
  uploadInProgress: Scalars["Boolean"]["output"]
}

export enum TemplateVersionStatus {
  Approved = "APPROVED",
  Cancelled = "CANCELLED",
  Draft = "DRAFT",
  Locked = "LOCKED",
  Review = "REVIEW",
  Revoked = "REVOKED",
  Suspended = "SUSPENDED",
}

export type TimeTemplate = {
  __typename?: "TimeTemplate"
  hour: IntTemplate
  minute: IntTemplate
  second: Scalars["String"]["output"]
}

export enum TransportModeCode {
  AirTransport = "AIR_TRANSPORT",
  FixedTransportInstallation = "FIXED_TRANSPORT_INSTALLATION",
  InlandWaterTransport = "INLAND_WATER_TRANSPORT",
  Mail = "MAIL",
  MaritimeTransport = "MARITIME_TRANSPORT",
  MultimodalTransport = "MULTIMODAL_TRANSPORT",
  NotApplicable = "NOT_APPLICABLE",
  NotSpecified = "NOT_SPECIFIED",
  RailTransport = "RAIL_TRANSPORT",
  RoadTransport = "ROAD_TRANSPORT",
}

export type TransportMovementCodeTemplate = {
  __typename?: "TransportMovementCodeTemplate"
  code: Scalars["String"]["output"]
  name: Scalars["String"]["output"]
}

export type TreatmentTemplate = {
  __typename?: "TreatmentTemplate"
  full_information?: Maybe<Scalars["String"]["output"]>
}

export type UpdateTemplateInput = {
  assignedTo?: InputMaybe<Scalars["String"]["input"]>
  businessArea: BusinessArea
  code: Scalars["String"]["input"]
  commodity?: InputMaybe<Scalars["String"]["input"]>
  description?: InputMaybe<Scalars["String"]["input"]>
  destinationMarket: DestinationMarket
  displayedDescription?: InputMaybe<Scalars["String"]["input"]>
  effective?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  expire?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  exportCertificateType?: InputMaybe<CertificateType>
  id: Scalars["ID"]["input"]
  importCertificateType?: InputMaybe<ImportCertificateType>
  majorVersion: Scalars["Int"]["input"]
  minorVersion: Scalars["Int"]["input"]
  reasonForUpdate?: InputMaybe<Scalars["String"]["input"]>
  title: Scalars["String"]["input"]
}

export type WineAnalytesTolerancesInput = {
  chemistryTest?: InputMaybe<Scalars["String"]["input"]>
  dealcoholisedWine?: InputMaybe<Scalars["String"]["input"]>
  max?: InputMaybe<Scalars["Float"]["input"]>
  min?: InputMaybe<Scalars["Float"]["input"]>
  red?: InputMaybe<Scalars["Boolean"]["input"]>
  wineCategory?: InputMaybe<Scalars["String"]["input"]>
}

export type WineAnalytesTolerancesResponse = {
  __typename?: "WineAnalytesTolerancesResponse"
  destinationMarket?: Maybe<DestinationMarket>
}

export type WineAnalytesTolerancesResponseInput = {
  dealcoholisedWine?: InputMaybe<Scalars["String"]["input"]>
  red: Scalars["Boolean"]["input"]
  testResults: Array<ChemistryTest>
  wineCategory?: InputMaybe<Scalars["String"]["input"]>
}

export type WineAnalytesTolerancesResponseRowInput = {
  destinationMarket?: InputMaybe<DestinationMarket>
}

export type WineAnalytesTolerancesType = DecisionTableBaseInput & {
  __typename?: "WineAnalytesTolerancesType"
  chemistryTest?: Maybe<Scalars["String"]["output"]>
  dealcoholisedWine?: Maybe<Scalars["String"]["output"]>
  max?: Maybe<Scalars["Float"]["output"]>
  min?: Maybe<Scalars["Float"]["output"]>
  red?: Maybe<Scalars["Boolean"]["output"]>
  validFrom?: Maybe<Scalars["AWSDateTime"]["output"]>
  validUntil?: Maybe<Scalars["AWSDateTime"]["output"]>
  wineCategory?: Maybe<Scalars["String"]["output"]>
}

export enum WineDisputedReason {
  Malodorous = "MALODOROUS",
  Oxidised = "OXIDISED",
  Tainted = "TAINTED",
}

export type WineFieldsTemplate = {
  __typename?: "WineFieldsTemplate"
  alternative_wine_names?: Maybe<Scalars["String"]["output"]>
  colour?: Maybe<Scalars["String"]["output"]>
  eu_category?: Maybe<Scalars["String"]["output"]>
  eu_ingredients?: Maybe<EuIngredientsTemplate>
  geographic_indication?: Maybe<Scalars["String"]["output"]>
  grape_variety?: Maybe<Scalars["String"]["output"]>
  name?: Maybe<Scalars["String"]["output"]>
  package?: Maybe<WinePackageTemplate>
  special_process?: Maybe<Scalars["String"]["output"]>
  style?: Maybe<Scalars["String"]["output"]>
  vintage?: Maybe<Scalars["String"]["output"]>
  wine_description?: Maybe<Scalars["String"]["output"]>
}

export type WinePackageTemplate = {
  __typename?: "WinePackageTemplate"
  bottle_size: MeasureTemplate
  bottles_per_case: IntTemplate
  cases: IntTemplate
  extra_bottles: IntTemplate
}

export enum WinePackaging {
  Cs = "CS",
  Ft = "FT",
}

export type WineProductFieldInput = {
  certificateType?: InputMaybe<CertificateType>
  destinationMarket?: InputMaybe<DestinationMarket>
}

export type WineProductFieldType = DecisionTableBaseInput & {
  __typename?: "WineProductFieldType"
  certificateType?: Maybe<CertificateType>
  destinationMarket?: Maybe<DestinationMarket>
  validFrom?: Maybe<Scalars["AWSDateTime"]["output"]>
  validUntil?: Maybe<Scalars["AWSDateTime"]["output"]>
}

export type Winemaking = {
  __typename?: "Winemaking"
  confirmations?: Maybe<Array<Maybe<WinemakingConfirmation>>>
  status?: Maybe<ProcessStatus>
  wsmp?: Maybe<Scalars["ID"]["output"]>
}

export type WinemakingConfirmation = {
  __typename?: "WinemakingConfirmation"
  confirmed?: Maybe<Scalars["Boolean"]["output"]>
  disputedReason?: Maybe<Scalars["String"]["output"]>
  disputedResolution?: Maybe<Scalars["String"]["output"]>
  market: DestinationMarket
  status: WinemakingStatus
}

export type WinemakingConfirmationInput = {
  confirmed?: InputMaybe<Scalars["Boolean"]["input"]>
  disputedReason?: InputMaybe<Scalars["String"]["input"]>
  disputedResolution?: InputMaybe<Scalars["String"]["input"]>
  market: DestinationMarket
}

export enum WinemakingStatus {
  Confirmed = "CONFIRMED",
  Disputed = "DISPUTED",
  NotApplicable = "NOT_APPLICABLE",
  Requested = "REQUESTED",
}

export type AddOrUpdateWineProductMutationVariables = Exact<{
  product: B2GProductInput
}>

export type AddOrUpdateWineProductMutation = {
  __typename?: "Mutation"
  addProduct: { __typename?: "B2GProduct"; id: string; commodity: string }
}

export type NewReferencedDocumentMutationVariables = Exact<{
  filename?: InputMaybe<Scalars["String"]["input"]>
}>

export type NewReferencedDocumentMutation = {
  __typename?: "Mutation"
  newReferencedDocument: {
    __typename?: "NewReferencedDocument"
    id: string
    url: string
  }
}

export type NewApiKeyMutationVariables = Exact<{ [key: string]: never }>

export type NewApiKeyMutation = {
  __typename?: "Mutation"
  newSearchApiKey?: {
    __typename?: "SearchApiKey"
    apiKey: string
    expires: any
  } | null
}

export type NewPartyApiKeyMutationVariables = Exact<{ [key: string]: never }>

export type NewPartyApiKeyMutation = {
  __typename?: "Mutation"
  newPartyApiKey?: {
    __typename?: "SearchApiKey"
    apiKey: string
    expires: any
  } | null
}

export type DeleteSupportingDocumentMutationVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type DeleteSupportingDocumentMutation = {
  __typename?: "Mutation"
  deleteReferencedDocument: string
}

export type MutateDraftExportCertificateRequestMutationVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]["input"]>
  referencedDocuments?: InputMaybe<
    Array<B2GReferencedDocumentInput> | B2GReferencedDocumentInput
  >
  exporterDeclaration?: InputMaybe<B2GExporterDeclarationInput>
  destination?: InputMaybe<B2GCountryInput>
  consignment?: InputMaybe<B2GConsignmentInput>
  commodity?: InputMaybe<Scalars["String"]["input"]>
  certificateType?: InputMaybe<CertificateType>
  certificateDelivery?: InputMaybe<B2GCertificateDeliveryInput>
}>

export type MutateDraftExportCertificateRequestMutation = {
  __typename?: "Mutation"
  draftExportCertificateRequest: {
    __typename?: "B2GExportCertificateRequest"
    id: string
    commodity?: string | null
    certificateType?: CertificateType | null
    market?: DestinationMarket | null
    destination?: { __typename?: "B2GCountry"; id: string } | null
  }
}

export type MutateRaiseExportCertificateRequestMutationVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type MutateRaiseExportCertificateRequestMutation = {
  __typename?: "Mutation"
  raiseExportCertificateRequest?: {
    __typename?: "B2GExportCertificateRequest"
    id: string
  } | null
}

export type MutateDeleteExportRequestMutationVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type MutateDeleteExportRequestMutation = {
  __typename?: "Mutation"
  deleteExportRequest: boolean
}

export type CreateSensoryProcessMutationVariables = Exact<{
  productId: Scalars["ID"]["input"]
  reference: Scalars["String"]["input"]
  disputed: Scalars["Boolean"]["input"]
  disputedReason?: InputMaybe<WineDisputedReason>
  comment?: InputMaybe<Scalars["String"]["input"]>
  winemaker: Scalars["String"]["input"]
  assessmentDate: Scalars["AWSDateTime"]["input"]
}>

export type CreateSensoryProcessMutation = {
  __typename?: "Mutation"
  completeSensoryProcess: {
    __typename?: "SensoryProcessRequest"
    id: string
    productId: string
    reference: string
    disputed: boolean
    disputedReason?: WineDisputedReason | null
    comment?: string | null
    assessmentDate: any
    winemaker: { __typename?: "B2GContact"; personName?: string | null }
  }
}

export type RequestChemistryTestMutationVariables = Exact<{
  productId: Scalars["ID"]["input"]
  expectedAlcoholContent?: InputMaybe<Scalars["Float"]["input"]>
  laboratory: Scalars["String"]["input"]
  packagingReference?: InputMaybe<Scalars["String"]["input"]>
  packagingDate?: InputMaybe<Scalars["AWSDateTime"]["input"]>
  sampleCollectionDate: Scalars["AWSDateTime"]["input"]
  testRequired: Scalars["ID"]["input"]
}>

export type RequestChemistryTestMutation = {
  __typename?: "Mutation"
  requestChemistryTest: {
    __typename?: "ChemistryTestResponse"
    status?: string | null
    cancelledReason?: string | null
    firstResultReportedDate?: string | null
    receipt: {
      __typename?: "ChemistryTestReceipt"
      laboratoryReference?: string | null
      receiptDate?: any | null
      sampleSuitable?: boolean | null
      unsuitableReason?: string | null
    }
    request: {
      __typename?: "ChemistryTestRequest"
      expectedAlcoholContent?: number | null
      laboratory: string
      packagingDate?: any | null
      packagingReference?: string | null
      sampleCollectionDate: any
      testRequired: string
      sampleReference: string
      wineTestReference: string
    }
  }
}

export type AddBottlingRunsMutationVariables = Exact<{
  input: BottlingRunInput
}>

export type AddBottlingRunsMutation = {
  __typename?: "Mutation"
  addBottlingRuns: {
    __typename?: "BottlingRunResponse"
    processId: string
    runs: Array<{
      __typename?: "BottlingRunData"
      id: string
      bottleVolume: string
      bottlesPerCase: number
      cases: number
      date: any
      extraBottles?: number | null
      reference: string
    }>
  }
}

export type AddFlexiFillingRunsMutationVariables = Exact<{
  input: FlexiFillingRunInput
}>

export type AddFlexiFillingRunsMutation = {
  __typename?: "Mutation"
  addFlexiFillingRuns: {
    __typename?: "FlexiFillingRunResponse"
    processId: string
    runs: Array<{
      __typename?: "FlexiFillingRunData"
      date: any
      litres: number
      id: string
      reference: string
    }>
  }
}

export type StartPackagingMutationVariables = Exact<{
  productId: Scalars["ID"]["input"]
}>

export type StartPackagingMutation = {
  __typename?: "Mutation"
  startPackaging: {
    __typename?: "ProcessResponse"
    id: string
    type: ProductProcessType
    task?: {
      __typename?: "ProcessTask"
      taskId: string
      assignedTo?: string | null
    } | null
  }
}

export type RequestConfirmationMutationVariables = Exact<{
  input: RequestConfirmationInput
}>

export type RequestConfirmationMutation = {
  __typename?: "Mutation"
  requestConfirmation: {
    __typename?: "RequestConfirmationResponse"
    wsmp: string
    processId: string
  }
}

export type ConfirmPackagingMutationVariables = Exact<{
  input: ConfirmPackagingInput
}>

export type ConfirmPackagingMutation = {
  __typename?: "Mutation"
  confirmPackaging: {
    __typename?: "ConfirmPackagingResponse"
    confirmed: boolean
    disputeReason?: string | null
    disputeResolution?: string | null
    processId: string
  }
}

export type CancelProcessMutationVariables = Exact<{
  processId: Scalars["ID"]["input"]
  cancelledReason?: InputMaybe<Scalars["String"]["input"]>
}>

export type CancelProcessMutation = {
  __typename?: "Mutation"
  cancelProcess: {
    __typename?: "CancelledProcessResponse"
    status?: string | null
    cancelledReason?: string | null
    firstResultReportedDate?: string | null
    receipt: {
      __typename?: "ChemistryTestReceipt"
      laboratoryReference?: string | null
      receiptDate?: any | null
      sampleSuitable?: boolean | null
      unsuitableReason?: string | null
    }
    request: {
      __typename?: "ChemistryTestRequest"
      expectedAlcoholContent?: number | null
      laboratory: string
      packagingDate?: any | null
      packagingReference?: string | null
      sampleCollectionDate: any
      testRequired: string
      sampleReference: string
      wineTestReference: string
    }
  }
}

export type StartWinemakingMutationVariables = Exact<{
  productId: Scalars["ID"]["input"]
}>

export type StartWinemakingMutation = {
  __typename?: "Mutation"
  startWinemaking: {
    __typename?: "ProcessResponse"
    type: ProductProcessType
    id: string
    task?: {
      __typename?: "ProcessTask"
      assignedTo?: string | null
      taskId: string
    } | null
  }
}

export type ConfirmWinemakingMutationVariables = Exact<{
  input: ConfirmWinemakingInput
}>

export type ConfirmWinemakingMutation = {
  __typename?: "Mutation"
  confirmWinemaking: string
}

export type ResetConfirmationRequestMutationVariables = Exact<{
  input: ResetConfirmationRequestInput
}>

export type ResetConfirmationRequestMutation = {
  __typename?: "Mutation"
  resetConfirmationRequest: string
}

export type ListFeatureFlagsQueryVariables = Exact<{ [key: string]: never }>

export type ListFeatureFlagsQuery = {
  __typename?: "Query"
  getFeatureFlags: Array<string>
}

export type ProductFormQueryVariables = Exact<{
  certificateType?: InputMaybe<CertificateType>
  country?: InputMaybe<Scalars["String"]["input"]>
  commodity?: InputMaybe<Scalars["String"]["input"]>
}>

export type ProductFormQuery = {
  __typename?: "Query"
  productForm: Array<{
    __typename?: "FormletField"
    type?: FormletFieldType | null
    required?: boolean | null
    readonly?: boolean | null
    numberOfLines?: number | null
    label?: string | null
    id: string
    description?: string | null
    collection?: string | null
    nested?: Array<{
      __typename?: "FormletNestedFields"
      type: FormletFieldType
      id: string
      collection?: string | null
    } | null> | null
    options?: Array<{
      __typename?: "FormletOption"
      value: string
      label: string
    } | null> | null
    numberConstrain?: {
      __typename?: "NumberConstrain"
      step: number
      min?: number | null
      max?: number | null
    } | null
    filter?: {
      __typename?: "FormletCollectionFilter"
      attribute: string
      value: string
    } | null
  } | null>
}

export type QueryExportCertificateBaseQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type QueryExportCertificateBaseQuery = {
  __typename?: "Query"
  exportCertificateRequest?: {
    __typename?: "B2GExportCertificateRequest"
    id: string
    commodity?: string | null
    certificateType?: CertificateType | null
    destination?: { __typename?: "B2GCountry"; id: string } | null
  } | null
}

export type QueryExportCertificateDetailsQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type QueryExportCertificateDetailsQuery = {
  __typename?: "Query"
  exportCertificateRequest?: {
    __typename?: "B2GExportCertificateRequest"
    id: string
    status: ExportCertificateStatus
    certificateNumber?: string | null
  } | null
}

export type ReferencedDocumentQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type ReferencedDocumentQuery = {
  __typename?: "Query"
  referencedDocument?: {
    __typename?: "ReferencedDocument"
    uploadInProgress: boolean
    id: string
    filename?: string | null
  } | null
}

export type DownloadSupportingDocumentQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type DownloadSupportingDocumentQuery = {
  __typename?: "Query"
  downloadReferencedDocument: {
    __typename?: "ReferencedDocumentUrl"
    documentName: string
    url: string
  }
}

export type GetProductQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type GetProductQuery = {
  __typename?: "Query"
  product: {
    __typename?: "B2GProduct"
    id: string
    productNumber?: string | null
    modifiable: boolean
    description: string
    wine?: {
      __typename?: "B2GWineFields"
      totalPackagedLitres?: number | null
      availableLitres?: number | null
      exportedLitres?: number | null
      shippingFormat?: WinePackaging | null
      EUCategory?: string | null
    } | null
    eligibilityStatus?: Array<{
      __typename?: "B2GEligibilityStatus"
      market: DestinationMarket
      status: B2GEligibilityStatusType
      expiryDate?: any | null
    }> | null
  }
}

export type QueryValidateQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type QueryValidateQuery = {
  __typename?: "Query"
  validate?: Array<{
    __typename?: "B2GValidationErrors"
    error?: string | null
    path?: string | null
    type?: B2GError | null
  } | null> | null
}

export type QueryValidateProductQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type QueryValidateProductQuery = {
  __typename?: "Query"
  validateProduct?: Array<{
    __typename?: "B2GValidationErrors"
    error?: string | null
    path?: string | null
    type?: B2GError | null
  } | null> | null
}

export type QuerySfExporterQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type QuerySfExporterQuery = {
  __typename?: "Query"
  sfExporter: {
    __typename?: "SalesForceParty"
    nzbn?: string | null
    name: string
    id: string
    addresses: Array<{
      __typename?: "SalesForceAddress"
      addressType: string
      city: string
      country: string
      street: string
      postalCode?: string | null
      isPrimary: boolean
    }>
    contacts: Array<{
      __typename?: "SalesForceContact"
      name: string
      role?: string | null
      emails: Array<{
        __typename?: "SalesForceEmail"
        emailAddress: string
        isPrimary: boolean
      }>
      phones: Array<{
        __typename?: "SalesForcePhone"
        telephoneNumber: string
        isPrimary: boolean
      }>
    }>
    identifiers: Array<{
      __typename?: "SalesForceIdentifier"
      value: string
      id: string
      description: string
    }>
  }
}

export type GetAvailableProcessesQueryVariables = Exact<{
  productId: Scalars["ID"]["input"]
}>

export type GetAvailableProcessesQuery = {
  __typename?: "Query"
  availableProcesses: Array<{
    __typename?: "ProcessType"
    description: string
    id: ProductProcessType
  }>
}

export type QueryProductProcessesSensoryEvaluationQueryVariables = Exact<{
  productId: Scalars["ID"]["input"]
}>

export type QueryProductProcessesSensoryEvaluationQuery = {
  __typename?: "Query"
  productProcesses: Array<{
    __typename?: "ProductProcessResponse"
    processId: string
    processType: ProductProcessType
    taskId?: string | null
    attributes:
      | { __typename?: "ChemistryTestResponse" }
      | { __typename?: "Packaging" }
      | {
          __typename?: "SensoryProcessRequest"
          assessmentDate: any
          reference: string
          disputed: boolean
          disputedReason?: WineDisputedReason | null
          id: string
          productId: string
          comment?: string | null
          winemaker: { __typename?: "B2GContact"; personName?: string | null }
        }
      | { __typename?: "Winemaking" }
  }>
}

export type QueryProductProcessesChemistryTestQueryVariables = Exact<{
  productId: Scalars["ID"]["input"]
}>

export type QueryProductProcessesChemistryTestQuery = {
  __typename?: "Query"
  productProcesses: Array<{
    __typename?: "ProductProcessResponse"
    processId: string
    processType: ProductProcessType
    taskId?: string | null
    attributes:
      | {
          __typename?: "ChemistryTestResponse"
          status?: string | null
          cancelledReason?: string | null
          firstResultReportedDate?: string | null
          receipt: {
            __typename?: "ChemistryTestReceipt"
            laboratoryReference?: string | null
            receiptDate?: any | null
            sampleSuitable?: boolean | null
            unsuitableReason?: string | null
          }
          request: {
            __typename?: "ChemistryTestRequest"
            expectedAlcoholContent?: number | null
            laboratory: string
            packagingDate?: any | null
            packagingReference?: string | null
            sampleCollectionDate: any
            sampleReference: string
            testRequired: string
            wineTestReference: string
          }
        }
      | { __typename?: "Packaging" }
      | { __typename?: "SensoryProcessRequest" }
      | { __typename?: "Winemaking" }
  }>
}

export type QueryProductProcessBottlingRunsQueryVariables = Exact<{
  productId: Scalars["ID"]["input"]
}>

export type QueryProductProcessBottlingRunsQuery = {
  __typename?: "Query"
  productProcesses: Array<{
    __typename?: "ProductProcessResponse"
    confirmationTaskId?: string | null
    requestTaskId?: string | null
    resetTaskId?: string | null
    taskId?: string | null
    processType: ProductProcessType
    processId: string
    attributes:
      | { __typename?: "ChemistryTestResponse" }
      | {
          __typename?: "Packaging"
          wsmp?: string | null
          exportedLitres?: number | null
          availableLitres: number
          status: PackagingStatus
          totalPackagedLitres: number
          confirmation?: {
            __typename?: "PackagingConfirmation"
            confirmed: boolean
            disputeReason?: string | null
            disputeResolution?: string | null
          } | null
          runs: Array<
            | {
                __typename?: "BottlingRunData"
                id: string
                bottleVolume: string
                bottlesPerCase: number
                cases: number
                date: any
                extraBottles?: number | null
                litres: number
                reference: string
              }
            | { __typename?: "FlexiFillingRunData" }
          >
          volumeAdjustment?: Array<{
            __typename?: "ManualVolumeAdjustment"
            date?: any | null
            litres?: number | null
            name?: string | null
            reason?: string | null
          } | null> | null
        }
      | { __typename?: "SensoryProcessRequest" }
      | { __typename?: "Winemaking" }
  }>
}

export type QueryProductProcessFillingRunsQueryVariables = Exact<{
  productId: Scalars["ID"]["input"]
}>

export type QueryProductProcessFillingRunsQuery = {
  __typename?: "Query"
  productProcesses: Array<{
    __typename?: "ProductProcessResponse"
    confirmationTaskId?: string | null
    requestTaskId?: string | null
    resetTaskId?: string | null
    taskId?: string | null
    processType: ProductProcessType
    processId: string
    attributes:
      | { __typename?: "ChemistryTestResponse" }
      | {
          __typename?: "Packaging"
          wsmp?: string | null
          exportedLitres?: number | null
          availableLitres: number
          status: PackagingStatus
          totalPackagedLitres: number
          confirmation?: {
            __typename?: "PackagingConfirmation"
            confirmed: boolean
            disputeReason?: string | null
            disputeResolution?: string | null
          } | null
          runs: Array<
            | { __typename?: "BottlingRunData" }
            | {
                __typename?: "FlexiFillingRunData"
                date: any
                id: string
                litres: number
                reference: string
              }
          >
          volumeAdjustment?: Array<{
            __typename?: "ManualVolumeAdjustment"
            date?: any | null
            litres?: number | null
            name?: string | null
            reason?: string | null
          } | null> | null
        }
      | { __typename?: "SensoryProcessRequest" }
      | { __typename?: "Winemaking" }
  }>
}

export type QueryProductDeclarationQueryVariables = Exact<{
  commodity: Scalars["String"]["input"]
}>

export type QueryProductDeclarationQuery = {
  __typename?: "Query"
  productFormlet: {
    __typename?: "Form"
    declarations: Array<{
      __typename?: "DeclarationFormletSection"
      id: string
      type: FormletFieldType
      fields: {
        __typename?: "DeclarationFormletFields"
        market: {
          __typename?: "FormletField"
          id: string
          value?: string | null
          label?: string | null
        }
        text: {
          __typename?: "FormletField"
          id: string
          type?: FormletFieldType | null
          label?: string | null
        }
        eligibilityCriteria: {
          __typename?: "FormletField"
          id: string
          value?: string | null
        }
      }
    }>
  }
}

export type FindAvailableTemplatesQueryVariables = Exact<{
  commodity: Scalars["String"]["input"]
  countryCode: Scalars["String"]["input"]
}>

export type FindAvailableTemplatesQuery = {
  __typename?: "Query"
  availableTemplates?: Array<{
    __typename?: "AvailableTemplateFields"
    id: string
    code: string
    title: string
    exportCertificateType?: CertificateType | null
    commodity?: string | null
    destinationMarket: DestinationMarket
    displayedDescription?: string | null
    minorVersion: number
    majorVersion: number
  }> | null
}

export type QueryProductProcessWinemakingQueryVariables = Exact<{
  id: Scalars["ID"]["input"]
}>

export type QueryProductProcessWinemakingQuery = {
  __typename?: "Query"
  productProcesses: Array<{
    __typename?: "ProductProcessResponse"
    confirmationTaskId?: string | null
    requestTaskId?: string | null
    resetTaskId?: string | null
    taskId?: string | null
    processType: ProductProcessType
    processId: string
    attributes:
      | { __typename?: "ChemistryTestResponse" }
      | { __typename?: "Packaging" }
      | { __typename?: "SensoryProcessRequest" }
      | {
          __typename?: "Winemaking"
          wsmp?: string | null
          confirmations?: Array<{
            __typename?: "WinemakingConfirmation"
            status: WinemakingStatus
            market: DestinationMarket
            disputedResolution?: string | null
            disputedReason?: string | null
            confirmed?: boolean | null
          } | null> | null
        }
  }>
}

export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>["__apiType"]

  constructor(
    private value: string,
    public __meta__?: Record<string, any>,
  ) {
    super(value)
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value
  }
}

export const AddOrUpdateWineProductDocument = new TypedDocumentString(`
    mutation addOrUpdateWineProduct($product: B2GProductInput!) {
  addProduct(product: $product, ingredients: []) {
    id
    commodity
  }
}
    `) as unknown as TypedDocumentString<
  AddOrUpdateWineProductMutation,
  AddOrUpdateWineProductMutationVariables
>
export const NewReferencedDocumentDocument = new TypedDocumentString(`
    mutation newReferencedDocument($filename: String = "") {
  newReferencedDocument(filename: $filename) {
    id
    url
  }
}
    `) as unknown as TypedDocumentString<
  NewReferencedDocumentMutation,
  NewReferencedDocumentMutationVariables
>
export const NewApiKeyDocument = new TypedDocumentString(`
    mutation newApiKey {
  newSearchApiKey {
    apiKey
    expires
  }
}
    `) as unknown as TypedDocumentString<
  NewApiKeyMutation,
  NewApiKeyMutationVariables
>
export const NewPartyApiKeyDocument = new TypedDocumentString(`
    mutation newPartyApiKey {
  newPartyApiKey {
    apiKey
    expires
  }
}
    `) as unknown as TypedDocumentString<
  NewPartyApiKeyMutation,
  NewPartyApiKeyMutationVariables
>
export const DeleteSupportingDocumentDocument = new TypedDocumentString(`
    mutation deleteSupportingDocument($id: ID!) {
  deleteReferencedDocument(id: $id)
}
    `) as unknown as TypedDocumentString<
  DeleteSupportingDocumentMutation,
  DeleteSupportingDocumentMutationVariables
>
export const MutateDraftExportCertificateRequestDocument =
  new TypedDocumentString(`
    mutation mutateDraftExportCertificateRequest($id: ID, $referencedDocuments: [B2GReferencedDocumentInput!], $exporterDeclaration: B2GExporterDeclarationInput, $destination: B2GCountryInput, $consignment: B2GConsignmentInput, $commodity: String = "", $certificateType: CertificateType, $certificateDelivery: B2GCertificateDeliveryInput) {
  draftExportCertificateRequest(
    request: {id: $id, referencedDocuments: $referencedDocuments, exporterDeclaration: $exporterDeclaration, destination: $destination, consignment: $consignment, commodity: $commodity, certificateType: $certificateType, certificateDelivery: $certificateDelivery}
  ) {
    id
    destination {
      id
    }
    commodity
    certificateType
    market
  }
}
    `) as unknown as TypedDocumentString<
    MutateDraftExportCertificateRequestMutation,
    MutateDraftExportCertificateRequestMutationVariables
  >
export const MutateRaiseExportCertificateRequestDocument =
  new TypedDocumentString(`
    mutation mutateRaiseExportCertificateRequest($id: ID!) {
  raiseExportCertificateRequest(id: $id) {
    id
  }
}
    `) as unknown as TypedDocumentString<
    MutateRaiseExportCertificateRequestMutation,
    MutateRaiseExportCertificateRequestMutationVariables
  >
export const MutateDeleteExportRequestDocument = new TypedDocumentString(`
    mutation mutateDeleteExportRequest($id: ID!) {
  deleteExportRequest(id: $id)
}
    `) as unknown as TypedDocumentString<
  MutateDeleteExportRequestMutation,
  MutateDeleteExportRequestMutationVariables
>
export const CreateSensoryProcessDocument = new TypedDocumentString(`
    mutation CreateSensoryProcess($productId: ID!, $reference: String!, $disputed: Boolean!, $disputedReason: WineDisputedReason, $comment: String, $winemaker: String!, $assessmentDate: AWSDateTime!) {
  completeSensoryProcess(
    input: {productId: $productId, reference: $reference, disputed: $disputed, disputedReason: $disputedReason, comment: $comment, winemaker: {personName: $winemaker}, assessmentDate: $assessmentDate}
  ) {
    id
    productId
    reference
    disputed
    disputedReason
    comment
    winemaker {
      personName
    }
    assessmentDate
  }
}
    `) as unknown as TypedDocumentString<
  CreateSensoryProcessMutation,
  CreateSensoryProcessMutationVariables
>
export const RequestChemistryTestDocument = new TypedDocumentString(`
    mutation RequestChemistryTest($productId: ID!, $expectedAlcoholContent: Float, $laboratory: String!, $packagingReference: String, $packagingDate: AWSDateTime, $sampleCollectionDate: AWSDateTime!, $testRequired: ID!) {
  requestChemistryTest(
    input: {productId: $productId, attributes: {expectedAlcoholContent: $expectedAlcoholContent, laboratory: $laboratory, packagingReference: $packagingReference, packagingDate: $packagingDate, sampleCollectionDate: $sampleCollectionDate, testRequired: $testRequired}}
  ) {
    status
    cancelledReason
    firstResultReportedDate
    receipt {
      laboratoryReference
      receiptDate
      sampleSuitable
      unsuitableReason
    }
    request {
      expectedAlcoholContent
      laboratory
      packagingDate
      packagingReference
      sampleCollectionDate
      testRequired
      sampleReference
      wineTestReference
    }
  }
}
    `) as unknown as TypedDocumentString<
  RequestChemistryTestMutation,
  RequestChemistryTestMutationVariables
>
export const AddBottlingRunsDocument = new TypedDocumentString(`
    mutation AddBottlingRuns($input: BottlingRunInput!) {
  addBottlingRuns(input: $input) {
    processId
    runs {
      id
      bottleVolume
      bottlesPerCase
      cases
      date
      extraBottles
      reference
    }
  }
}
    `) as unknown as TypedDocumentString<
  AddBottlingRunsMutation,
  AddBottlingRunsMutationVariables
>
export const AddFlexiFillingRunsDocument = new TypedDocumentString(`
    mutation AddFlexiFillingRuns($input: FlexiFillingRunInput!) {
  addFlexiFillingRuns(input: $input) {
    processId
    runs {
      date
      litres
      id
      reference
    }
  }
}
    `) as unknown as TypedDocumentString<
  AddFlexiFillingRunsMutation,
  AddFlexiFillingRunsMutationVariables
>
export const StartPackagingDocument = new TypedDocumentString(`
    mutation StartPackaging($productId: ID!) {
  startPackaging(productId: $productId) {
    id
    type
    task {
      taskId
      assignedTo
    }
  }
}
    `) as unknown as TypedDocumentString<
  StartPackagingMutation,
  StartPackagingMutationVariables
>
export const RequestConfirmationDocument = new TypedDocumentString(`
    mutation RequestConfirmation($input: RequestConfirmationInput!) {
  requestConfirmation(input: $input) {
    wsmp
    processId
  }
}
    `) as unknown as TypedDocumentString<
  RequestConfirmationMutation,
  RequestConfirmationMutationVariables
>
export const ConfirmPackagingDocument = new TypedDocumentString(`
    mutation ConfirmPackaging($input: ConfirmPackagingInput!) {
  confirmPackaging(input: $input) {
    confirmed
    disputeReason
    disputeResolution
    processId
  }
}
    `) as unknown as TypedDocumentString<
  ConfirmPackagingMutation,
  ConfirmPackagingMutationVariables
>
export const CancelProcessDocument = new TypedDocumentString(`
    mutation CancelProcess($processId: ID!, $cancelledReason: String) {
  cancelProcess(input: {processId: $processId, cancelledReason: $cancelledReason}) {
    status
    cancelledReason
    firstResultReportedDate
    receipt {
      laboratoryReference
      receiptDate
      sampleSuitable
      unsuitableReason
    }
    request {
      expectedAlcoholContent
      laboratory
      packagingDate
      packagingReference
      sampleCollectionDate
      testRequired
      sampleReference
      wineTestReference
    }
  }
}
    `) as unknown as TypedDocumentString<
  CancelProcessMutation,
  CancelProcessMutationVariables
>
export const StartWinemakingDocument = new TypedDocumentString(`
    mutation StartWinemaking($productId: ID!) {
  startWinemaking(productId: $productId) {
    type
    task {
      assignedTo
      taskId
    }
    id
  }
}
    `) as unknown as TypedDocumentString<
  StartWinemakingMutation,
  StartWinemakingMutationVariables
>
export const ConfirmWinemakingDocument = new TypedDocumentString(`
    mutation ConfirmWinemaking($input: ConfirmWinemakingInput!) {
  confirmWinemaking(input: $input)
}
    `) as unknown as TypedDocumentString<
  ConfirmWinemakingMutation,
  ConfirmWinemakingMutationVariables
>
export const ResetConfirmationRequestDocument = new TypedDocumentString(`
    mutation ResetConfirmationRequest($input: ResetConfirmationRequestInput!) {
  resetConfirmationRequest(input: $input)
}
    `) as unknown as TypedDocumentString<
  ResetConfirmationRequestMutation,
  ResetConfirmationRequestMutationVariables
>
export const ListFeatureFlagsDocument = new TypedDocumentString(`
    query listFeatureFlags {
  getFeatureFlags
}
    `) as unknown as TypedDocumentString<
  ListFeatureFlagsQuery,
  ListFeatureFlagsQueryVariables
>
export const ProductFormDocument = new TypedDocumentString(`
    query productForm($certificateType: CertificateType, $country: String, $commodity: String) {
  productForm(
    input: {certificateType: $certificateType, commodity: $commodity, country: $country}
  ) {
    type
    required
    readonly
    numberOfLines
    nested {
      type
      id
      collection
    }
    label
    id
    options {
      value
      label
    }
    description
    collection
    numberConstrain {
      step
      min
      max
    }
    filter {
      attribute
      value
    }
  }
}
    `) as unknown as TypedDocumentString<
  ProductFormQuery,
  ProductFormQueryVariables
>
export const QueryExportCertificateBaseDocument = new TypedDocumentString(`
    query queryExportCertificateBase($id: ID!) {
  exportCertificateRequest(id: $id) {
    id
    commodity
    certificateType
    destination {
      id
    }
  }
}
    `) as unknown as TypedDocumentString<
  QueryExportCertificateBaseQuery,
  QueryExportCertificateBaseQueryVariables
>
export const QueryExportCertificateDetailsDocument = new TypedDocumentString(`
    query queryExportCertificateDetails($id: ID!) {
  exportCertificateRequest(id: $id) {
    id
    status
    certificateNumber
  }
}
    `) as unknown as TypedDocumentString<
  QueryExportCertificateDetailsQuery,
  QueryExportCertificateDetailsQueryVariables
>
export const ReferencedDocumentDocument = new TypedDocumentString(`
    query referencedDocument($id: ID!) {
  referencedDocument(id: $id) {
    uploadInProgress
    id
    filename
  }
}
    `) as unknown as TypedDocumentString<
  ReferencedDocumentQuery,
  ReferencedDocumentQueryVariables
>
export const DownloadSupportingDocumentDocument = new TypedDocumentString(`
    query downloadSupportingDocument($id: ID!) {
  downloadReferencedDocument(id: $id) {
    documentName
    url
  }
}
    `) as unknown as TypedDocumentString<
  DownloadSupportingDocumentQuery,
  DownloadSupportingDocumentQueryVariables
>
export const GetProductDocument = new TypedDocumentString(`
    query GetProduct($id: ID!) {
  product(id: $id) {
    id
    productNumber
    modifiable
    description
    wine {
      totalPackagedLitres
      availableLitres
      exportedLitres
      shippingFormat
      EUCategory
    }
    eligibilityStatus {
      market
      status
      expiryDate
    }
  }
}
    `) as unknown as TypedDocumentString<
  GetProductQuery,
  GetProductQueryVariables
>
export const QueryValidateDocument = new TypedDocumentString(`
    query queryValidate($id: ID!) {
  validate(id: $id) {
    error
    path
    type
  }
}
    `) as unknown as TypedDocumentString<
  QueryValidateQuery,
  QueryValidateQueryVariables
>
export const QueryValidateProductDocument = new TypedDocumentString(`
    query queryValidateProduct($id: ID!) {
  validateProduct(id: $id) {
    error
    path
    type
  }
}
    `) as unknown as TypedDocumentString<
  QueryValidateProductQuery,
  QueryValidateProductQueryVariables
>
export const QuerySfExporterDocument = new TypedDocumentString(`
    query querySfExporter($id: ID!) {
  sfExporter(id: $id) {
    addresses {
      addressType
      city
      country
      street
      postalCode
      isPrimary
    }
    nzbn
    name
    id
    contacts {
      emails {
        emailAddress
        isPrimary
      }
      name
      phones {
        telephoneNumber
        isPrimary
      }
      role
    }
    identifiers {
      value
      id
      description
    }
  }
}
    `) as unknown as TypedDocumentString<
  QuerySfExporterQuery,
  QuerySfExporterQueryVariables
>
export const GetAvailableProcessesDocument = new TypedDocumentString(`
    query getAvailableProcesses($productId: ID!) {
  availableProcesses(productId: $productId) {
    description
    id
  }
}
    `) as unknown as TypedDocumentString<
  GetAvailableProcessesQuery,
  GetAvailableProcessesQueryVariables
>
export const QueryProductProcessesSensoryEvaluationDocument =
  new TypedDocumentString(`
    query queryProductProcessesSensoryEvaluation($productId: ID!) {
  productProcesses(input: {productId: $productId, filter: {processType: SENSORY}}) {
    processId
    processType
    taskId
    attributes {
      ... on SensoryProcessRequest {
        assessmentDate
        reference
        disputed
        disputedReason
        id
        productId
        winemaker {
          personName
        }
        comment
      }
    }
  }
}
    `) as unknown as TypedDocumentString<
    QueryProductProcessesSensoryEvaluationQuery,
    QueryProductProcessesSensoryEvaluationQueryVariables
  >
export const QueryProductProcessesChemistryTestDocument =
  new TypedDocumentString(`
    query queryProductProcessesChemistryTest($productId: ID!) {
  productProcesses(
    input: {productId: $productId, filter: {processType: CHEMISTRY}}
  ) {
    processId
    processType
    taskId
    attributes {
      ... on ChemistryTestResponse {
        status
        cancelledReason
        firstResultReportedDate
        receipt {
          laboratoryReference
          receiptDate
          sampleSuitable
          unsuitableReason
        }
        request {
          expectedAlcoholContent
          laboratory
          packagingDate
          packagingReference
          sampleCollectionDate
          sampleReference
          testRequired
          wineTestReference
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<
    QueryProductProcessesChemistryTestQuery,
    QueryProductProcessesChemistryTestQueryVariables
  >
export const QueryProductProcessBottlingRunsDocument = new TypedDocumentString(`
    query QueryProductProcessBottlingRuns($productId: ID!) {
  productProcesses(
    input: {productId: $productId, filter: {processType: BOTTLING_RUN}}
  ) {
    attributes {
      ... on Packaging {
        wsmp
        exportedLitres
        confirmation {
          confirmed
          disputeReason
          disputeResolution
        }
        availableLitres
        runs {
          ... on BottlingRunData {
            id
            bottleVolume
            bottlesPerCase
            cases
            date
            extraBottles
            litres
            reference
          }
        }
        status
        totalPackagedLitres
        volumeAdjustment {
          date
          litres
          name
          reason
        }
      }
    }
    confirmationTaskId
    requestTaskId
    resetTaskId
    taskId
    processType
    processId
  }
}
    `) as unknown as TypedDocumentString<
  QueryProductProcessBottlingRunsQuery,
  QueryProductProcessBottlingRunsQueryVariables
>
export const QueryProductProcessFillingRunsDocument = new TypedDocumentString(`
    query QueryProductProcessFillingRuns($productId: ID!) {
  productProcesses(
    input: {productId: $productId, filter: {processType: FLEXI_FILLING}}
  ) {
    attributes {
      ... on Packaging {
        wsmp
        exportedLitres
        confirmation {
          confirmed
          disputeReason
          disputeResolution
        }
        availableLitres
        runs {
          ... on FlexiFillingRunData {
            date
            id
            litres
            reference
          }
        }
        status
        totalPackagedLitres
        volumeAdjustment {
          date
          litres
          name
          reason
        }
      }
    }
    confirmationTaskId
    requestTaskId
    resetTaskId
    taskId
    processType
    processId
  }
}
    `) as unknown as TypedDocumentString<
  QueryProductProcessFillingRunsQuery,
  QueryProductProcessFillingRunsQueryVariables
>
export const QueryProductDeclarationDocument = new TypedDocumentString(`
    query queryProductDeclaration($commodity: String!) {
  productFormlet(input: {commodity: $commodity}) {
    declarations {
      id
      type
      fields {
        market {
          id
          value
          label
        }
        text {
          id
          type
          label
        }
        eligibilityCriteria {
          id
          value
        }
      }
    }
  }
}
    `) as unknown as TypedDocumentString<
  QueryProductDeclarationQuery,
  QueryProductDeclarationQueryVariables
>
export const FindAvailableTemplatesDocument = new TypedDocumentString(`
    query FindAvailableTemplates($commodity: String!, $countryCode: String!) {
  availableTemplates(commodity: $commodity, countryCode: $countryCode) {
    id
    code
    title
    exportCertificateType
    commodity
    destinationMarket
    displayedDescription
    minorVersion
    majorVersion
  }
}
    `) as unknown as TypedDocumentString<
  FindAvailableTemplatesQuery,
  FindAvailableTemplatesQueryVariables
>
export const QueryProductProcessWinemakingDocument = new TypedDocumentString(`
    query queryProductProcessWinemaking($id: ID!) {
  productProcesses(input: {filter: {processType: WINE_MAKING}, productId: $id}) {
    attributes {
      ... on Winemaking {
        wsmp
        confirmations {
          status
          market
          disputedResolution
          disputedReason
          confirmed
        }
      }
    }
    confirmationTaskId
    requestTaskId
    resetTaskId
    taskId
    processType
    processId
  }
}
    `) as unknown as TypedDocumentString<
  QueryProductProcessWinemakingQuery,
  QueryProductProcessWinemakingQueryVariables
>
